import React, { useEffect } from "react";
import { connect } from "react-redux";

import PreviewAttachment from "./previewAttachment";

import { closeModal, popModal } from "../actions/modals";
import { ATTACHMENT_MODAL } from "../actions/types";

import "./styles/modalRoot.scss";



function ModalContainer(props) {
  const handleBackgroundClick = (e) => {
    if (e.target === e.currentTarget) {
      props.onBackgroundClick();
    }
  };
  useEffect(() => {
    document.body.style.overflow = props.isOpen ? "hidden" : "auto";
    return () => document.body.style.overflow = "auto";
  }, []);
  return (
    <div className="recon-modal-container">
        <div className="recon-modal" onClick={handleBackgroundClick}>
            <div className="recon-modal-body">
              <div className="recon-modal-header">
                <button type="button" className="close-modal bg-primary" onClick={props.onClose}>
                  <i className="fa fa-times" style={{color: "white", width: "100%", height: "100%", padding: "1px"}} />
                </button>
              </div>
              <div className="recon-modal-content">
                <h4 className="--bs-dark-text-emphasis">{props.title}</h4>
                {props.children}
              </div>
            </div>
        </div>
    </div>
  );
}

function ModalWrapper(props) {
  const { modalList, onClose, onBackgroundClick } = props;
  let selectedModal = modalList[modalList.length - 1];
  const isOpen = modalList.length > 0;

  if (!selectedModal) {
    return <React.Fragment />;
  }

  let defaultProps = {
    isOpen,
    onClose,
    onBackgroundClick,
  };

  switch (selectedModal.type) {
    case ATTACHMENT_MODAL:
      return <PreviewAttachment currentProps={selectedModal.props} defaultProps={defaultProps} />;
    default:
      return <React.Fragment />;
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    modalList: state.modals.modalList,
    title: ownProps.title,
  };
};

const mapDispatchToProps = {
    onClose: closeModal,
    onBackgroundClick: popModal,
};

export {ModalContainer};

export default connect(mapStateToProps, mapDispatchToProps)(ModalWrapper);