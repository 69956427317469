import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import html2canvas from 'html2canvas';
import domtoimage from 'dom-to-image';
import jsPDF from 'jspdf';
import AllInputs from '../../inputs/AllInputs';
import { lookupChecked, lookupVehiclePropertyValueByName, activeVehicle } from '../../actions/lookups';
import { addVehicleProperty, addVehiclePropertyRaw } from '../../actions/AllUpdates';

class DetailSlip extends Component {
	constructor(props) {
		super(props);

		this.state = {
 addPartOrderError: '', authorized_by: '', detaileddate: '', ds_misc: '', ds_misc_price_1: '', ds_misc_2: '', ds_misc_price_2: '', ds_employee: '', ds_done_date: '',
};
		this.onChange = this.onChange.bind(this);
		this.onBlur = this.onBlur.bind(this);
		this.onClick = this.onClick.bind(this);
		this.onPrint = this.onPrint.bind(this);
		this.onChangeChecked = this.onChangeChecked.bind(this);

		this.updatePay = this.updatePay.bind(this);
	}

	componentDidMount() {
		const authorized_by = this.vehicleProperty('authorized_by');
		const detaileddate = this.vehicleProperty('detaileddate');
		const ds_misc = this.vehicleProperty('ds_misc');
		const ds_misc_price_1 = this.vehicleProperty('ds_misc_price_1');
		const ds_misc_2 = this.vehicleProperty('ds_misc_2');
		const ds_misc_price_2 = this.vehicleProperty('ds_misc_price_2');
		const ds_employee = this.vehicleProperty('ds_employee');
		const ds_done_date = this.vehicleProperty('ds_done_date');
		this.setState({
 authorized_by, detaileddate, ds_misc, ds_misc_price_1, ds_misc_price_2, ds_misc_2, ds_employee, ds_done_date,
});
	}

	vehicleProperty(name) {
		const self = this;
		const vehicle = [];
		const value = _.filter(vehicle.vehicle_properties, o => o.key === name);

		if (value.length > 0) {
			if (typeof (value[0]) !== undefined && typeof (value[0].value) !== undefined) {
				return value[0].value;
			}
		} else {
			return '';
		}
	}

	onChange(e) {
		this.setState({ [e.target.dataset.metakey]: e.target.value });
	}

	onClick(e) {
		const data = {
			vehicle_id: this.props.active_vehicle.id,
		};

		this.props.addPartOrder(data, 'addPartOrderError');
	}

	onChangeChecked(e) {
	}

	updatePay() {
		var vehicle = [];
		var vehicle_id = this.props.active_vehicle.id;
		var all_inputs = document.getElementsByClassName(`detail_slip_data_${vehicle.id}`);

		var all_pay = 0;
		var all_sale = 0;

		for (var i = 0; i < all_inputs.length; i++) {
			if (all_inputs[i].checked) {
		        all_pay += parseFloat(all_inputs[i].dataset.pay) || 0;
		        all_sale += parseFloat(all_inputs[i].dataset.sale) || 0;
		     }
		}

		var data1 = {
			vehicle_id,
			key: 'ds_total_pay',
			value: Number(all_pay),
		};
		var data2 = {
			vehicle_id,
			key: 'ds_total_sale',
			value: Number(all_sale),
		};
		this.props.addVehiclePropertyRaw(data1, 'detail slip');
		this.props.addVehiclePropertyRaw(data2, 'detail slip');
		this.props.activeVehicle(vehicle_id);
	}

	onBlur(e) {
		const self = this;
		var vehicle_id = this.props.active_vehicle.id;
		var department_id = this.props.active_vehicle.department_id;
		var errorType = 'updateVehicleError';
		var key = e.target.dataset.metakey;
		var keylowercase = key.toLowerCase();
		var value = e.target.value;

		var data = {
			vehicle_id,
			key,
			value,
		};

		this.props.addVehicleProperty(data, errorType);
	}

	onPrint(e) {
		const vehicle = this.props.active_vehicle;
		const print_page = document.getElementById('recon_tracker_display_form');
		domtoimage.toPng(print_page)
			.then((canvasOrDataUrl) => {
				if (typeof canvasOrDataUrl == 'string') {
					const pdf = new jsPDF();
				    pdf.addImage(canvasOrDataUrl, 'PNG', 0, 0);
				    pdf.save(`recon_tracker_${vehicle.stock}.pdf`);
				} else {
					document.body.appendChild(canvasOrDataUrl);
				}
			})
			.catch((error) => {
				console.log('oops, something went wrong!', error);
			});
	}

	render() {
		const self = this;
		const vehicle = [];
		const errors = this.state;
		const arbitrationDeadline = _.find(vehicle.vehicle_properties, { key: 'arbitration deadline' }) || '';
		const safetyCheckCompletedBy = _.find(vehicle.vehicle_properties, { key: 'safety check completed by' }) || '';
		const waitingOnParts = _.find(vehicle.vehicle_properties, { key: 'waiting on parts' }) || '';
		const waitingOnPartsNotes = _.find(vehicle.vehicle_properties, { key: 'waiting on parts notes' }) || '';
		const clearedForDetail = _.find(vehicle.vehicle_properties, { key: 'cleared for detail' }) || '';
		const detailCompletedBy = _.find(vehicle.vehicle_properties, { key: 'detail completed by' }) || '';
		const qualityInspectedBy = _.find(vehicle.vehicle_properties, { key: 'quality inspected by' }) || '';
		const dateDetailCompleted = _.find(vehicle.vehicle_properties, { key: 'date detail completed' }) || '';
		const photosTaken = _.find(vehicle.vehicle_properties, { key: 'photos taken' }) || '';

		return (
			<div className="container">
				<button onClick={this.onPrint} className="btn btn-success float-right">Print</button>
				<div className="card small-font pad-right-2 pad-top-2 pad-left-2" id={'recon_tracker_display_form'} style={{ width: '800px' }}>
					<div className="card">
						<div className="card-text text-center">
							<h3>
								<div className="pull-left">
#DS
									{vehicle.id}
								</div>
Detail Authorization Slip
							</h3>
						</div>

								    <div className="row">

							<div className="col-md-12 space-top-1">
								<div className="row pad-bottom-1 pad-top-2">
									<div className="col-md-3">
										<div className="row">
											<div className="col-md-3">Year:</div>
											<div className="col-md-9"><input disabled type="text" data-vid={vehicle.id} data-metakey="year" className="form-control" value={vehicle.year} /></div>

										</div>
									</div>
									<div className="col-md-3">
										<div className="row">
											<div className="col-md-3">Make:</div>
											<div className="col-md-9"><input disabled type="text" data-vid={vehicle.id} data-metakey="make" className="form-control" value={vehicle.make} /></div>
										</div>
									</div>
									<div className="col-md-6">
										<div className="row">
											<div className="col-md-3">Model:</div>
											<div className="col-md-9"><input disabled type="text" data-vid={vehicle.id} data-metakey="model" className="form-control" value={vehicle.model} /></div>
										</div>
									</div>
								</div>

								<div className="row pad-bottom-1">
									<div className="col-md-6">
										<div className="row">
											<div className="col-md-3">Color:</div>
											<div className="col-md-9"><input disabled type="text" data-vid={vehicle.id} data-metakey="color" className="form-control" value={vehicle.color} /></div>
										</div>
									</div>
									<div className="col-md-3">
										<div className="row">
											<div className="col-md-3">Stock:</div>
											<div className="col-md-9"><input disabled type="text" data-vid={vehicle.id} data-metakey="stock number" className="form-control" value={vehicle.stock} /></div>
										</div>
									</div>
									<div className="col-md-3">
										<div className="row">
											<div className="col-md-3">Date:</div>
											<div className="col-md-9"><input type="date" onChange={this.onChange} onBlur={this.onBlur} data-vid={vehicle.id} data-metakey="detaileddate" className="form-control" value={this.state.detaileddate} /></div>
										</div>
									</div>
								</div>

								<div className="row pad-bottom-1">
									<div className="col-md-6">
										<div className="row">
											<div className="col-md-5">Authorized By:</div>
											<div className="col-md-7"><input type="text" onChange={this.onChange} onBlur={this.onBlur} data-vid={vehicle.id} data-metakey="authorized_by" className="form-control" value={this.state.authorized_by} /></div>

										</div>
									</div>

								</div>
							</div>

						</div>
						<div className="row">
							<div className="col-md-12 pad-bottom-1">

								<div className="col-md-12 column no-more-tables">
									<table className="col-md-12 table-bordered table-hover table-condensed cf">
										<thead className="cf">
											<tr>
												<th className="text-center desk-w-10">
																	Sale
												</th>
												<th className="text-center desk-w-10">
																Pay
												</th>
												<th className="text-center desk-w-10">
																FLH
												</th>
												<th className="text-center desk-w-10" />
												<th className="text-center desk-w-20">
																Desc
												</th>
												<th className="text-center desk-w-10">
																Sale
												</th>
												<th className="text-center desk-w-10">
																Pay
												</th>
												<th className="text-center desk-w-10">
																FLH
												</th>
												<th className="text-center desk-w-10" />
												<th className="text-center desk-w-20">
																Desc
												</th>
											</tr>
										</thead>
										<tbody className="detail_slip_form">
											<tr>
												<td>$60</td>
												<td />
												<td>1</td>
												<td><input data-sale="60" data-pay="0" type="checkbox" onChange={this.onChangeChecked} data-vid={vehicle.id} data-metakey="each_extra_hour_over_4" checked={this.props.lookupChecked(vehicle.id, 'each_extra_hour_over_4')} className={`detail_slip_data_${vehicle.id}`} /></td>
												<td>Each Extra Hour over 4</td>

												<td>$120</td>
												<td />
												<td>1</td>
												<td><input data-sale="120" data-pay="0" type="checkbox" onChange={this.onChangeChecked} data-vid={vehicle.id} data-metakey="polish_headlamps" checked={this.props.lookupChecked(vehicle.id, 'polish_headlamps')} className={`detail_slip_data_${vehicle.id}`} /></td>
												<td>Polish Headlights</td>

											</tr>
											<tr>
												<td>$360</td>
												<td />
												<td>4.5</td>
												<td><input data-sale="360" data-pay="0" type="checkbox" onChange={this.onChangeChecked} data-vid={vehicle.id} data-metakey="complete_truck_suv" checked={this.props.lookupChecked(vehicle.id, 'complete_truck_suv')} className={`detail_slip_data_${vehicle.id}`} /></td>
												<td>Complete Truck/SUV</td>

												<td></td>
												<td />
												<td>.5</td>
												<td><input data-sale="0" data-pay="0" type="checkbox" onChange={this.onChangeChecked} data-vid={vehicle.id} data-metakey="perma_shield" className={`detail_slip_data_${vehicle.id} pull-left`} checked={this.props.lookupChecked(vehicle.id, 'perma_shield')} /></td>
												<td>Perma Shield</td>

											</tr>
											<tr>
												<td>$300</td>
												<td />
												<td>4</td>
												<td><input data-sale="300" data-pay="0" type="checkbox" onChange={this.onChangeChecked} data-vid={vehicle.id} data-metakey="complete_detail" checked={this.props.lookupChecked(vehicle.id, 'complete_detail')} className={`detail_slip_data_${vehicle.id}`} /></td>
												<td>Complete Detail</td>

												<td></td>
												<td />
												<td>1.0</td>
												<td><input data-sale="0" data-pay="0" type="checkbox" onChange={this.onChangeChecked} data-vid={vehicle.id} data-metakey="form_other" className={`detail_slip_data_${vehicle.id} pull-left`} checked={this.props.lookupChecked(vehicle.id, 'form_other')} /></td>
												<td>Other</td>

											</tr>
											<tr>
												<td>$400</td>
												<td />
												<td>4.5</td>
												<td><input data-sale="400" data-pay="0" type="checkbox" onChange={this.onChangeChecked} data-vid={vehicle.id} data-metakey="complete_det_cp_truck" checked={this.props.lookupChecked(vehicle.id, 'complete_det_cp_truck')} className={`detail_slip_data_${vehicle.id}`} /></td>
												<td>Complete Det. CP Truck</td>

												<td>$80</td>
												<td />
												<td>.5</td>
												<td><input data-sale="80" data-pay="0" type="checkbox" onChange={this.onChangeChecked} data-vid={vehicle.id} data-metakey="degrease_engine" className={`detail_slip_data_${vehicle.id} pull-left`} checked={this.props.lookupChecked(vehicle.id, 'degrease_engine')} /></td>
												<td>Degrease Engine</td>

											</tr>
											<tr>
												<td>$360</td>
												<td />
												<td>4</td>
												<td><input data-sale="360" data-pay="0" type="checkbox" onChange={this.onChangeChecked} data-vid={vehicle.id} data-metakey="complete_det_cp_car" checked={this.props.lookupChecked(vehicle.id, 'complete_det_cp_car')} className={`detail_slip_data_${vehicle.id}`} /></td>
												<td>Complete Det. CP Car</td>

												<td>$100</td>
												<td />
												<td>.5</td>
												<td><input data-sale="100" data-pay="0" type="checkbox" onChange={this.onChangeChecked} data-vid={vehicle.id} data-metakey="door_edge_gaurd" className={`detail_slip_data_${vehicle.id} pull-left`} checked={this.props.lookupChecked(vehicle.id, 'door_edge_gaurd')} /></td>
												<td>Door Edge Guard</td>

											</tr>
											<tr>
												<td>$240</td>
												<td />
												<td>2.20</td>
												<td><input data-sale="240" data-pay="0" type="checkbox" onChange={this.onChangeChecked} data-vid={vehicle.id} data-metakey="ext_detail_cp_truck" checked={this.props.lookupChecked(vehicle.id, 'ext_detail_cp_truck')} className={`detail_slip_data_${vehicle.id}`} /></td>
												<td>Ext. Detail CP Truck</td>

												<td>$25</td>
												<td />
												<td>.5</td>
												<td><input data-sale="25" data-pay="0" type="checkbox" onChange={this.onChangeChecked} data-vid={vehicle.id} data-metakey="sold_delivery" checked={this.props.lookupChecked(vehicle.id, 'sold_delivery')} className={`detail_slip_data_${vehicle.id}`} /></td>
												<td>Sold Delivery</td>

											</tr>
											<tr>
												<td>$220</td>
												<td />
												<td>2</td>
												<td><input data-sale="220" data-pay="0" type="checkbox" onChange={this.onChangeChecked} data-vid={vehicle.id} data-metakey="ext_detail_cp_car" checked={this.props.lookupChecked(vehicle.id, 'ext_detail_cp_car')} className={`detail_slip_data_${vehicle.id}`} /></td>
												<td>Ext. Detail CP Car</td>
												
												<td>$100</td>
												<td />
												<td>1</td>
												<td><input data-sale="100" data-pay="0" type="checkbox" onChange={this.onChangeChecked} data-vid={vehicle.id} data-metakey="exterior_refresh" checked={this.props.lookupChecked(vehicle.id, 'exterior_refresh')} className={`detail_slip_data_${vehicle.id}`} /></td>
												<td>Exterior Refresh</td>
											</tr>
											<tr>
												<td>$240</td>
												<td />
												<td>2.20</td>
												<td><input data-sale="240" data-pay="0" type="checkbox" onChange={this.onChangeChecked} data-vid={vehicle.id} data-metakey="int_detail_cp_truck" checked={this.props.lookupChecked(vehicle.id, 'int_detail_cp_truck')} className={`detail_slip_data_${vehicle.id}`} /></td>
												<td>Int. Detail CP Truck</td>

												<td />
												<td />
												<td />
												<td />
												<td />

											</tr>
											<tr>
												<td>$220</td>
												<td />
												<td>2</td>
												<td><input data-sale="220" data-pay="0" type="checkbox" onChange={this.onChangeChecked} data-vid={vehicle.id} data-metakey="int_detail_cp_car" checked={this.props.lookupChecked(vehicle.id, 'int_detail_cp_car')} className={`detail_slip_data_${vehicle.id}`} /></td>
												<td>Int. Detail CP Car</td>

												<td />
												<td />
												<td />
												<td />
												<td />

											</tr>
											<tr>
												<td>$50</td>
												<td />
												<td>.5</td>
												<td><input data-sale="50" data-pay="0" type="checkbox" onChange={this.onChangeChecked} data-vid={vehicle.id} data-metakey="express_det_truck" checked={this.props.lookupChecked(vehicle.id, 'express_det_truck')} className={`detail_slip_data_${vehicle.id}`} /></td>
												<td>Express Wash Truck</td>

												<td />
												<td />
												<td />
												<td />
												<td />

											</tr>
											<tr>
												<td>$40</td>
												<td />
												<td>.5</td>
												<td><input data-sale="49" data-pay="15" type="checkbox" onChange={this.onChangeChecked} data-vid={vehicle.id} data-metakey="express_det_car" checked={this.props.lookupChecked(vehicle.id, 'express_det_car')} className={`detail_slip_data_${vehicle.id}`} /></td>
												<td>Express Wash Car</td>

												<td />
												<td />
												<td />
												<td />
												<td />

											</tr>

										</tbody>
									</table>
								</div>
								<div className="row pad-bottom-1 space-top-2">
									<div className="col-md-9">
										<div className="row">
											<div className="col-md-2">Misc:</div>
											<div className="col-md-10"><input type="text" onChange={this.onChange} onBlur={this.onBlur} data-vid={vehicle.id} data-metakey="ds_misc" className="form-control" value={this.state.ds_misc} /></div>

										</div>
									</div>
									<div className="col-md-3">
										<div className="row">
											<div className="col-md-5">Price $</div>
											<div className="col-md-7"><input type="text" onChange={this.onChange} onBlur={this.onBlur} data-vid={vehicle.id} data-metakey="ds_misc_price_1" className="form-control" value={this.state.ds_misc_price_1} /></div>
										</div>
									</div>

								</div>
								<div className="row pad-bottom-1">
									<div className="col-md-9">
										<div className="row">
											<div className="col-md-2">Misc:</div>
											<div className="col-md-10"><input type="text" onChange={this.onChange} onBlur={this.onBlur} data-vid={vehicle.id} data-metakey="ds_misc_2" className="form-control" value={this.state.ds_misc_2} /></div>

										</div>
									</div>
									<div className="col-md-3">
										<div className="row">
											<div className="col-md-5">Price $</div>
											<div className="col-md-7"><input type="text" onChange={this.onChange} onBlur={this.onBlur} data-vid={vehicle.id} data-metakey="ds_misc_price_2" className="form-control" value={this.state.ds_misc_price_2} /></div>
										</div>
									</div>

								</div>
								<div className="row pad-bottom-1">
									<div className="col-md-7">
										<div className="row">
											<div className="col-md-4">Employee:</div>
											<div className="col-md-8"><input type="text" onChange={this.onChange} onBlur={this.onBlur} data-vid={vehicle.id} data-metakey="ds_employee" className="form-control" value={this.state.ds_employee} /></div>

										</div>
									</div>
									<div className="col-md-5">
										<div className="row">
											<div className="col-md-5">Date done</div>
											<div className="col-md-7"><input type="date" onChange={this.onChange} onBlur={this.onBlur} data-vid={vehicle.id} data-metakey="ds_done_date" className="form-control datepicker" value={this.state.ds_done_date} /></div>
										</div>
									</div>

								</div>
								<div className="row pad-bottom-1">
									<div className="col-md-4">
										<div className="row">
											<div className="col-md-6">Total pay $</div>
											<div className="col-md-6"><input disabled type="text" onChange={this.onChange} onBlur={this.onBlur} data-vid={vehicle.id} data-metakey="ds_total_pay" className="form-control" value={this.props.lookupVehiclePropertyValueByName(vehicle.id, 'ds_total_pay')} /></div>

										</div>
									</div>
									<div className="col-md-4">
										<div className="row">
											<div className="col-md-6">Total sale $</div>
											<div className="col-md-6"><input disabled type="text" onChange={this.onChange} onBlur={this.onBlur} data-vid={vehicle.id} data-metakey="ds_total_sale" className="form-control" value={this.props.lookupVehiclePropertyValueByName(vehicle.id, 'ds_total_sale')} /></div>

										</div>
									</div>
									<div className="col-md-4">
										<div className="row">
											<div className="col-md-6">+ 61 E $</div>
											<div className="col-md-6"><input type="text" onChange={this.onChange} onBlur={this.onBlur} data-vid={vehicle.id} data-metakey="ds_61_e" className="form-control" value={this.props.lookupVehiclePropertyValueByName(vehicle.id, 'ds_61_e')} /></div>

										</div>
									</div>

								</div>

							</div>

						</div>
					</div>

				</div>
			</div>

		);
	}
}
const mappedActions = {
	lookupChecked,
	addVehicleProperty,
	lookupVehiclePropertyValueByName,
	activeVehicle,
	addVehiclePropertyRaw,
};

function mapStateToProps(state) {
	return { active_vehicle: state.settings_refresh.active_vehicle };
}

export default connect(mapStateToProps, mappedActions)(DetailSlip);
