import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Modal from 'react-modal';
import _ from 'lodash';
import Dropdown from '../../inputs/Dropdown';
import TextFieldGroup from '../../inputs/TextFieldGroup';
import InputTypes from '../../inputs/InputTypes';
import AddOptionButton from '../../inputs/AddOptionButton';
import DisplayOptions from './DisplayOptions';
import {
	addVehicleColumnOption,
	updateVehicleColumn,
} from '../../actions/apiDepartmentUpdates';
import {
	setCurrentTab,
} from '../../actions/apiSetInfo';
import { activeEditVehicleColumn } from '../../actions/lookups';
import DropdownMultiple from '../../inputs/DropdownMultiple';

const customStyles = {
	overlay: {
		position: 'fixed',
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		backgroundColor: 'rgba(0, 0, 0, 0.85)',
	},
	content: {
		top: '40%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
		padding: '0',
	},
};

class TabEditVehicleColumn extends Component {
	constructor(props) {
		super(props);

		const vehicle_column = props.vehicle_column;

		this.state = {
			modalIsOpen: false,
			option_name: '',
		};

		this.onChange = this.onChange.bind(this);
		this.onChangeOption = this.onChangeOption.bind(this);
		this.onClick = this.onClick.bind(this);
		this.editVehicleColumn = this.editVehicleColumn.bind(this);
		this.onBlur = this.onBlur.bind(this);
		this.onTabClick = this.onTabClick.bind(this);
		this.selectRelatedInstallations = this.selectRelatedInstallations.bind(this);
	}

	componentDidMount() {
		this.setState({
			automove_trigger_value: this.props.vehicle_column.automove_trigger_value,
		});
	}

	onChange(e) {
		this.setState({ [e.target.field]: e.target.value });
	}

	onChangeOption(e) {
		this.setState({ [e.target.name]: e.target.value });
	}

	onTabClick(e) {
		this.props.setCurrentTab(e.target.name);
	}

	onClick(e) {
		e.preventDefault();
		const name = this.state.option_name;
		const { id } = this.props.vehicle_column;
		const errorType = 'addVehicleColumnOption';
		const data = {
			vehicle_column_id: id,
			name,
			special: '',
			active: 'true',
		};

		this.props.addVehicleColumnOption(id, data, errorType);
	}

	inputColumns() {
		const options = this.props.input_types.map((option) => {
			let selected = false;
			const dataid = 0;
			if (this.state.input_name === parseInt(option.id)) {
				selected = true;
			}

			var obj = {
				id: option.id, label: option.name, value: option.name, selected,
			};
			return obj;
		});
		return options || [];
	}

	automoveDestinations(value) {
		const remove_department_types = ['recently sold', 'data step', 'all reconditioning'];
		const options = this.props.departments.map((option) => {
			let selected = false;
			const dataid = 0;
			if (value === parseInt(option.id)) {
				selected = true;
			}

			var obj = {
				id: option.id, label: option.name, value: option.id, selected,
			};
			if (!remove_department_types.includes(option.department_type)) {
				return obj;
			}
		});
		return options.filter(e => e);
	}

	selectRelatedInstallations() {
		const selected_dealerships = this.props.vehicle_column.location.split(',');
		const locations = [];

		locations.map((option) => {
			selected_dealerships.push(option);
		});

		const options = this.props.set_dealerships.map((option) => {
			let selected = false;
			const dataid = 0;
			if (selected_dealerships.includes(option.id.toString())) {
				selected = true;
			}

			var obj = {
				id: option.id, label: option.subdomain, name: option.subdomain, selected,
			};
			return obj;
		});
		return options;
	}

	editVehicleColumn() {
		const vehicle_column = this.props.vehicle_column;
		const { name } = vehicle_column;
		const { input_name } = vehicle_column;
		const { id } = vehicle_column;
		const errorType = 'editVehicleColumnError';
		const data = {
			id,
			name,
			input_name,
		};

		this.props.updateVehicleColumn(id, data, errorType);
	}

	onBlur(e) {
		const name = e.target.name;
		const { id } = this.props.vehicle_column;
		var value = e.target.value;
		const errorType = 'editVehicleColumnError';
		if (name === 'name') {
			value = value.toLowerCase();
		}
		const data = {
			id,
			[name]: value,
		};

		this.props.updateVehicleColumn(id, data, errorType);
	}

	selectDepartments() {
		const options = this.props.departments.map((option) => {
			if (typeof option.name !== 'undefined') {
				var obj = { value: option.id, label: option.name };
			} else {
				var obj = { value: option.id, label: option.name };
			}
			return obj;
		});
		return options;
	}

	allFilters() {
		const options = this.props.all_filters.map((option) => {
			const obj = { value: option.id, label: `${option.filter_name} ( ${option.filter_target} ${option.filter_operator} ${option.filter_value} )` };
			return obj;
		});

		return options;
	}

	render() {
		const cols = [];
		const destinations = [];
		const vehicle_column = this.props.vehicle_column;
		if (vehicle_column.name) {
			cols.push(<div className="card" key={vehicle_column.id}>
				<div className="d-flex flex-column pad-left-2 pad-right-2">
					<div className="p-2 text-dark">
						<label className="control-label bg-dark text-white"># Name</label>
					</div>
					<p className="text-dark">
						<i className={'fa fa-info-circle text-dark'} />
						{' '}
The column name should not have things like "@-/.?*^&" and should be case insensitive for best practices. However the display name has no limitations.
					</p>
					<div className="p-2 text-dark">
						<InputTypes
							value={vehicle_column.name}
							type={'text'}
							name={'name'}
							updatetype="vehicle_column"
							vehicle_column_id={vehicle_column.id}
							onChange={this.onChange}
							onBlur={this.onBlur}

						/>
					</div>
					<div className="p-2 text-dark">
						<label className="control-label bg-dark text-white"># Choose Display Name -- If different then vehicle column name capitalized</label>
					</div>
					<p className="text-dark">
						<i className={'fa fa-info-circle text-dark'} />
						{' '}
Display name is handy when using special characters in the column name. The column name should not have things like "@-/.?*^&" for best practices. However the display name does has no limitations.
					</p>
					<div className="p-2 text-dark">
						<InputTypes
							type="text"
							value={vehicle_column.display_name}
							name={'display_name'}
							updatetype="vehicle_column"
							setdisabled={false}
							vehicle_column_id={vehicle_column.id}
							onChange={this.onChange}
							onBlur={this.onBlur}
						/>
					</div>

					<div className="p-2 text-dark">
						<label className="control-label bg-dark text-white"># Label 1 of Switch Display Name - Default is "Needed"</label>
					</div>
					<p className="text-dark">
						<i className={'fa fa-info-circle text-dark'} />
						{' '}
					</p>
					<div className="p-2 text-dark">
						<InputTypes
							type="text"
							value={vehicle_column.display_label_1}
							name={'display_label_1'}
							updatetype="vehicle_column"
							setdisabled={false}
							vehicle_column_id={vehicle_column.id}
							onChange={this.onChange}
							onBlur={this.onBlur}
						/>
					</div>

					<div className="p-2 text-dark">
						<label className="control-label bg-dark text-white"># Label 2 of Switch Display Name - Default is "Completed"</label>
					</div>
					<p className="text-dark">
						<i className={'fa fa-info-circle text-dark'} />
						{' '}
					</p>
					<div className="p-2 text-dark">
						<InputTypes
							type="text"
							value={vehicle_column.display_label_2}
							name={'display_label_2'}
							updatetype="vehicle_column"
							setdisabled={false}
							vehicle_column_id={vehicle_column.id}
							onChange={this.onChange}
							onBlur={this.onBlur}
						/>
					</div>

					<div className="p-2 text-dark"><label className="control-label bg-dark text-white"># Input Type</label></div>
					<p className="text-dark">
						<i className={'fa fa-info-circle text-dark'} />
						{' '}
Input types are special components and describe the initial functionality of a vehicle column. See
						{' '}
						<i>Input Type Definitions</i>
						{' '}
for further explanation of types.
					</p>
					<div className="p-2 text-dark">

						<Dropdown
							titleHelper={''}
							title={'Choose Input Type'}
							value={vehicle_column.input_name}
							name={'input_name'}
							vehicle_column_id={vehicle_column.id}
							update_type={'vehicle_column'}
							className={'form-control'}
							list={this.inputColumns()}
							update_type={'vehicle_column'}
						/>
					</div>
				</div>
			</div>);
			cols.push(<div className="card">
				<div className="card-header bg-dark text-white" />
				<div className="p-2 text-dark"><label className="control-label bg-dark text-white"># Options</label></div>
				<p className="text-dark">
					<i className={'fa fa-info-circle text-dark'} />
					{' '}
Add/Delete Options.
				</p>

				<div className="d-flex flex-column pad-left-2 pad-right-2">
					<div className="p-2 text-dark">
						<AddOptionButton
							field="option_name"
							label=""
							onChange={this.onChangeOption}
							onClick={this.onClick}
							value={this.state.option_name}
							type="add_option"
							vehicle_column_id={vehicle_column.id}
						/>
					</div>

					<DisplayOptions {...this.props} options={vehicle_column.options} column_id={vehicle_column.id} key="display_options" />
				</div>
			</div>);
			cols.push(<div className="card">
				<div className="card-header bg-dark text-white" />
				<div className="d-flex flex-column pad-left-2 pad-right-2">
					<div className="p-2 text-dark"><label className="control-label bg-dark text-white"># AutoNote On/Off</label></div>
					<p className="text-dark">
						<i className={'fa fa-info-circle text-dark'} />
						{' '}
Turn on an automatic note to be created based on a value of this vehicle column.
					</p>
					<div className="p-2 text-dark">
						<InputTypes
							type="checkbox-edit"
							value={vehicle_column.autonote}
							name={'autonote'}
							updatetype="vehicle_column"
							setdisabled={false}
							rowid={vehicle_column.id}
							onBlur={this.onBlur}
							vehicle_column_id={vehicle_column.id}
						/>
					</div>
					<div className="p-2 text-dark">
						<label className="control-label bg-dark text-white"># AutoNote Template (default note is column name)</label>
						<p className="text-dark">
							<i className={'fa fa-info-circle text-dark'} />
							{' '}
Use the format of "^name^ was marked ^value^". ^name^ willl be replaced by the vehicle column name and ^value^ will be replaced by the value of the column.
						</p>
						<InputTypes
							type="text"
							value={vehicle_column.autonote_text}
							name={'autonote_text'}
							updatetype="vehicle_column"
							setdisabled={false}
							vehicle_column_id={vehicle_column.id}
							onChange={this.onChange}
							onBlur={this.onBlur}
						/>
					</div>
				</div>
			</div>);
			cols.push(<div className="card">
				<div className="card-header bg-dark text-white" />
				<div className="d-flex flex-column pad-left-2 pad-right-2">
					<div className="p-2 text-dark"><label className="control-label bg-dark text-white"># Notification On/Off</label></div>
					<p className="text-dark">
						<i className={'fa fa-info-circle text-dark'} />
						{' '}
Currently not available - will allow you to send a notification based on a value of this column.
					</p>
					<div className="p-2 text-dark">
						<InputTypes
							type="checkbox-edit"
							value={vehicle_column.notification}
							name={'notification'}
							updatetype="vehicle_column"
							setdisabled={false}
							rowid={vehicle_column.id}
							onBlur={this.onBlur}
							vehicle_column_id={vehicle_column.id}
						/>
					</div>
					<div className="p-2 text-dark"><label className="control-label bg-dark text-white"># Notification Trigger Value</label></div>
					<p className="text-dark">
						<i className={'fa fa-info-circle text-dark'} />
						{' '}
Currently not available - the value the column must be to trigger a notification.
					</p>
					<div className="p-2 text-dark">
						<InputTypes
							type="text"
							value={vehicle_column.notification_trigger_value}
							name={'notification_trigger_value'}
							updatetype="vehicle_column"
							setdisabled={false}
							vehicle_column_id={vehicle_column.id}
							onChange={this.onChange}
							onBlur={this.onBlur}
						/>
					</div>
					<div className="p-2 text-dark"><label className="control-label bg-dark text-white"># Notification Text -- Include a message for this column</label></div>
					<p className="text-dark">
						<i className={'fa fa-info-circle text-dark'} />
						{' '}
Currently not available - The text to be sent in the notification. Use the format of "^name^ was marked ^value^". ^name^ willl be replaced by the vehicle column name and ^value^ will be replaced by the value of the column.
					</p>
					<div className="p-2 text-dark">
						<InputTypes
							type="text"
							value={vehicle_column.notification_text}
							name={'notification_text'}
							updatetype="vehicle_column"
							setdisabled={false}
							vehicle_column_id={vehicle_column.id}
							onChange={this.onChange}
							onBlur={this.onBlur}
						/>
					</div>
					<div className="p-2 text-dark"><label className="control-label bg-dark text-white"># Notification Users -- Send Notification to Users</label></div>
					<p className="text-dark">
						<i className={'fa fa-info-circle text-dark'} />
						{' '}
Currently not available - the selected users will receive a notificaiton based on the trigger value of the column.
					</p>
					<div className="p-2 text-dark">
						<InputTypes
							type="text"
							value={vehicle_column.notification_users}
							name={'notification_users'}
							updatetype="vehicle_column"
							setdisabled={false}
							vehicle_column_id={vehicle_column.id}
							onChange={this.onChange}
							onBlur={this.onBlur}
						/>
					</div>

				</div>
			</div>);
			cols.push(<div className="card">
				<div className="card-header bg-dark text-white" />
				<div className="d-flex flex-column pad-left-2 pad-right-2">
					<div className="p-2 text-dark"><label className="control-label bg-dark text-white"># Location Only - On/Off</label></div>
					<p className="text-dark">
						<i className={'fa fa-info-circle text-dark'} />
						{' '}
Multisite Store Feature -- column will only show up in a specific location. Most often used for "Techs" so you can reuse the Techs column but make each dropdown list store specific. The actual vehicle column name would be "xyz store - Techs" and "abc store - Techs" and then change display name to "Techs".
					</p>
					<div className="p-2 text-dark">
						<InputTypes
							type="checkbox-edit"
							value={vehicle_column.location_only}
							name={'location_only'}
							updatetype="vehicle_column"
							setdisabled={false}
							rowid={vehicle_column.id}
							onBlur={this.onBlur}
							vehicle_column_id={vehicle_column.id}
						/>
					</div>
					<div className="p-2 text-dark"><label className="control-label bg-dark text-white"># Locations -- (dealership)</label></div>
					<p className="text-dark">
						<i className={'fa fa-info-circle text-dark'} />
						{' '}
Multisite Store Feature -- choose the locations/dealerships this column should display.
					</p>
					<div className="p-2 text-dark">
						<DropdownMultiple
							{...this.props}
							titleHelper="Location"
							title="Location"
							list={this.selectRelatedInstallations()}
							update_type={'vehicle_column_locations'}
							vehicle_column_id={vehicle_column.id}
							dealers={vehicle_column.location}
						/>
					</div>

				</div>
			</div>);
			cols.push(<div className="card">
				<div className="card-header bg-dark text-white" />
				<div className="d-flex flex-column pad-left-2 pad-right-2">
					<div className="p-2 text-dark"><label className="control-label bg-dark text-white"># HelpNote On/Off</label></div>
					<p className="text-dark">
						<i className={'fa fa-info-circle text-dark'} />
						{' '}
Add a help message to be included in a hover or popup event next to the column.
					</p>
					<div className="p-2 text-dark">
						<InputTypes
							type="checkbox-edit"
							value={vehicle_column.helpnote}
							name={'helpnote'}
							updatetype="vehicle_column"
							setdisabled={false}
							rowid={vehicle_column.id}
							onBlur={this.onBlur}
							vehicle_column_id={vehicle_column.id}
						/>
					</div>
					<div className="p-2 text-dark"><label className="control-label bg-dark text-white"># HelpNote Text -- Include a help message for this column</label></div>
					<p className="text-dark">
						<i className={'fa fa-info-circle text-dark'} />
						{' '}
The message you would like to display to a user who hovers or clicks the help icon next to the column.
					</p>
					<div className="p-2 text-dark">
						<InputTypes
							type="text"
							value={vehicle_column.helpnote_text}
							name={'helpnote_text'}
							updatetype="vehicle_column"
							setdisabled={false}
							vehicle_column_id={vehicle_column.id}
							onChange={this.onChange}
							onBlur={this.onBlur}
						/>
					</div>

				</div>
			</div>);

			var destination = 'default';
			if (vehicle_column.automove === true) {
				if (vehicle_column.stocktype_dependent === true) {
					destination = 'stocktype_dependent';
				} else if (vehicle_column.condition_dependent === true) {
					destination = 'condition_dependent';
				}
			}

			cols.push(<div className="card">
				<div className="card-header bg-dark text-white" />
				<div className="d-flex flex-column pad-left-2 pad-right-2">
					<div className="p-2 text-dark"><label className="control-label bg-dark text-white"># AutoMove On/Off</label></div>
					<p className="text-dark">
						<i className={'fa fa-info-circle text-dark'} />
						{' '}
Create an automated move to another department/stage/bucket/queue based on a value of this column.
					</p>
					<div className="p-2 text-dark">
						<InputTypes
							type="checkbox-edit"
							value={vehicle_column.automove}
							name={'automove'}
							updatetype="vehicle_column"
							setdisabled={false}
							rowid={vehicle_column.id}
							onBlur={this.onBlur}
							vehicle_column_id={vehicle_column.id}
						/>
					</div>
					<div className="p-2 text-dark"><label className="control-label bg-dark text-white"># AutoMove Trigger Value</label></div>
					<p className="text-dark">
						<i className={'fa fa-info-circle text-dark'} />
						{' '}
The trigger value to initiate the move. Usually checkboxes would be a value of "true" or the switch value would be either "needed" or "completed". This value is also case insensitive.
					</p>
					<div className="p-2 text-dark">
						<InputTypes
							type="text"
							value={vehicle_column.automove_trigger_value}
							name={'automove_trigger_value'}
							updatetype="vehicle_column"
							setdisabled={false}
							vehicle_column_id={vehicle_column.id}
							onChange={this.onChange}
							onBlur={this.onBlur}
						/>
					</div>
					<div className="p-2 text-dark"><label className="control-label bg-dark text-white"># Department Only On/Off</label></div>
					<p className="text-dark">
						<i className={'fa fa-info-circle text-dark'} />
						{' '}
Turn this on to allow this automove only to occur if the vehicle is in a current department/stage/bucket/queue (not data steps, only locations) - you must use the "stocktype destinations" below.
					</p>
					<div className="p-2 text-dark">
						<InputTypes
							type="checkbox-edit"
							value={vehicle_column.department_only}
							name={'department_only'}
							updatetype="vehicle_column"
							setdisabled={false}
							rowid={vehicle_column.id}
							onBlur={this.onBlur}
							vehicle_column_id={vehicle_column.id}
						/>
					</div>
					<div className="p-2 text-dark"><label className="control-label bg-dark text-white"># StockType Destinations On/Off must be used if you are using the above # Department Only On/Off</label></div>
					<p className="text-dark">
						<i className={'fa fa-info-circle text-dark'} />
						{' '}
Turn this on if the automove destination is different based on the stocktype of "New","Used", or "Wholesale".
					</p>
					<div className="p-2 text-dark">
						<InputTypes
							type="checkbox-edit"
							value={vehicle_column.stocktype_dependent}
							name={'stocktype_dependent'}
							updatetype="vehicle_column"
							setdisabled={false}
							rowid={vehicle_column.id}
							onBlur={this.onBlur}
							vehicle_column_id={vehicle_column.id}
						/>
					</div>
					<div className="p-2 text-dark"><label className="control-label bg-dark text-white"># Filters On/Off</label></div>
					<p className="text-dark">
						<i className={'fa fa-info-circle text-dark'} />
						{' '}
Currently not available - Add filters/rules that must pass in order for the action to happen.
					</p>
					<div className="p-2 text-dark">
						<InputTypes
							type="checkbox-edit"
							value={vehicle_column.filter_dependent}
							name={'filter_dependent'}
							updatetype="vehicle_column"
							setdisabled={false}
							rowid={vehicle_column.id}
							onBlur={this.onBlur}
							vehicle_column_id={vehicle_column.id}
						/>
					</div>
					<div className="p-2 text-dark"><label className="control-label bg-dark text-white"># Condition Dependent On/Off</label></div>
					<p className="text-dark">
						<i className={'fa fa-info-circle text-dark'} />
						{' '}
Currently not available - Turn this on to create conditional automove destinations.
					</p>
					<div className="p-2 text-dark">
						<InputTypes
							type="checkbox-edit"
							value={vehicle_column.condition_dependent}
							name={'condition_dependent'}
							updatetype="vehicle_column"
							setdisabled={false}
							rowid={vehicle_column.id}
							onBlur={this.onBlur}
							vehicle_column_id={vehicle_column.id}
						/>
					</div>
				</div>
             </div>);

			switch (destination) {
			case 'stocktype_dependent':
				destinations.push(<div className="p-2 text-dark" key={`stocktype_dependent-${vehicle_column.id}`}>
					<p className="text-dark">
						<i className={'fa fa-info-circle text-dark'} />
						{' '}
Destination for "New" stocktypes.
					</p>
					<div className="form-group">
						<label className="control-label bg-dark text-white"># AutoMove Destination New</label>
						<Dropdown
							titleHelper={''}
							title={'Choose AutoMove Destination'}
							value={vehicle_column.ad_new}
							name={'ad_new'}
							vehicle_column_id={vehicle_column.id}
							update_type={'vehicle_column'}
							className={'form-control'}
							list={this.automoveDestinations(vehicle_column.ad_new)}
							update_type={'vehicle_column'}
						/>
					</div>
				</div>);
				destinations.push(<div className="p-2 text-dark" key={`stocktype_dependent2-${vehicle_column.id}`}>
					<p className="text-dark">
						<i className={'fa fa-info-circle text-dark'} />
						{' '}
Destination for "Used" stocktypes.
					</p>
					<div className="form-group">
						<label className="control-label bg-dark text-white"># AutoMove Destination Used</label>
						<Dropdown
							titleHelper={''}
							title={'Choose AutoMove Destination'}
							value={vehicle_column.ad_used}
							name={'ad_used'}
							vehicle_column_id={vehicle_column.id}
							update_type={'vehicle_column'}
							className={'form-control'}
							list={this.automoveDestinations(vehicle_column.ad_used)}
							update_type={'vehicle_column'}
						/>
					</div>
				</div>);
				destinations.push(<div className="p-2 text-dark" key={`stocktype_dependent3-${vehicle_column.id}`}>
					<p className="text-dark">
						<i className={'fa fa-info-circle text-dark'} />
						{' '}
Destination for "Wholesale" stocktypes.
					</p>
					<div className="form-group">
						<label className="control-label bg-dark text-white"># AutoMove Destination Wholesale</label>
						<Dropdown
							titleHelper={''}
							title={'Choose AutoMove Destination'}
							value={vehicle_column.ad_wholesale}
							name={'ad_wholesale'}
							vehicle_column_id={vehicle_column.id}
							update_type={'vehicle_column'}
							className={'form-control'}
							list={this.automoveDestinations(vehicle_column.ad_wholesale)}
							update_type={'vehicle_column'}
						/>
					</div>
				</div>);

				break;
			case 'condition_dependent':
				destinations.push(<div className="p-2 text-dark" key={`stocktype_dependent4-${vehicle_column.id}`}>
					<p className="text-dark">
						<i className={'fa fa-info-circle text-dark'} />
						{' '}
Destination for "Condition scenario 1".
					</p>
					<div className="form-group">
						<label className="control-label bg-dark text-white"># AutoMove Destination Condition 1</label>
						<Dropdown
							titleHelper={''}
							title={'Choose AutoMove Destination'}
							value={vehicle_column.ad_condition_1}
							name={'ad_condition_1'}
							vehicle_column_id={vehicle_column.id}
							update_type={'vehicle_column'}
							className={'form-control'}
							list={this.automoveDestinations(vehicle_column.ad_condition_1)}
							update_type={'vehicle_column'}
						/>
					</div>
				</div>);
				destinations.push(<div className="p-2 text-dark" key={`stocktype_dependent5-${vehicle_column.id}`}>
					<p className="text-dark">
						<i className={'fa fa-info-circle text-dark'} />
						{' '}
Destination for "Condition scenario 2".
					</p>
					<div className="form-group">
						<label className="control-label bg-dark text-white"># AutoMove Destination Condition 2</label>
						<Dropdown
							titleHelper={''}
							title={'Choose AutoMove Destination'}
							value={vehicle_column.ad_condition_2}
							name={'ad_condition_2'}
							vehicle_column_id={vehicle_column.id}
							update_type={'vehicle_column'}
							className={'form-control'}
							list={this.automoveDestinations(vehicle_column.ad_condition_2)}
							update_type={'vehicle_column'}
						/>
					</div>
				</div>);
				destinations.push(<div className="p-2 text-dark" key={`stocktype_dependent6-${vehicle_column.id}`}>
					<p className="text-dark">
						<i className={'fa fa-info-circle text-dark'} />
						{' '}
Destination for "Condition scenario 3".
					</p>
					<div className="form-group">
						<label className="control-label bg-dark text-white"># AutoMove Destination Condition 3</label>
						<Dropdown
							titleHelper={''}
							title={'Choose AutoMove Destination'}
							value={vehicle_column.ad_condition_3}
							name={'ad_condition_3'}
							vehicle_column_id={vehicle_column.id}
							update_type={'vehicle_column'}
							className={'form-control'}
							list={this.automoveDestinations(vehicle_column.ad_condition_3)}
							update_type={'vehicle_column'}
						/>
					</div>
				</div>);
				break;
			default:
				destinations.push(<div className="p-2 text-dark" key={`stocktype_dependent7-${vehicle_column.id}`}>
					<p className="text-dark">
						<i className={'fa fa-info-circle text-dark'} />
						{' '}
Default Destination.
					</p>
					<div className="form-group">
						<label className="control-label bg-dark text-white"># AutoMove Destination</label>
						<Dropdown
							titleHelper={''}
							title={'Choose AutoMove Destination'}
							value={vehicle_column.automove_destination}
							name={'automove_destination'}
							vehicle_column_id={vehicle_column.id}
							update_type={'vehicle_column'}
							className={'form-control'}
							list={this.automoveDestinations(vehicle_column.automove_destination)}
							update_type={'vehicle_column'}
						/>
					</div>
				</div>);
				break;
			}

			if (vehicle_column.department_only === true) {
				if (vehicle_column.stocktype_dependent === true) {
					cols.push(<div className="p-2 text-dark" key={`stocktype_dependent8-${vehicle_column.id}`}>
						<p className="text-dark">
							<i className={'fa fa-info-circle text-dark'} />
							{' '}
The Required Department/Stage/Queue/Bucket the New vehicle must be in to automove.
						</p>
						<div className="form-group">
							<label className="control-label bg-dark text-white"># Required Current Department -- New</label>
							<Dropdown
								titleHelper={''}
								title={'Choose Required Current Department'}
								value={vehicle_column.current_department_new}
								name={'current_department_new'}
								vehicle_column_id={vehicle_column.id}
								update_type={'vehicle_column'}
								className={'form-control'}
								list={this.automoveDestinations(vehicle_column.current_department_new)}
								update_type={'vehicle_column'}
							/>
						</div>
					</div>);
					cols.push(<div className="p-2 text-dark" key={`stocktype_dependent22-${vehicle_column.id}`}>
						<p className="text-dark">
							<i className={'fa fa-info-circle text-dark'} />
							{' '}
The Required Department/Stage/Queue/Bucket the Used vehicle must be in to automove.
						</p>
						<div className="form-group">
							<label className="control-label bg-dark text-white"># Required Current Department -- Used</label>
							<Dropdown
								titleHelper={''}
								title={'Choose Required Current Department'}
								value={vehicle_column.current_department_used}
								name={'current_department_used'}
								vehicle_column_id={vehicle_column.id}
								update_type={'vehicle_column'}
								className={'form-control'}
								list={this.automoveDestinations(vehicle_column.current_department_used)}
								update_type={'vehicle_column'}
							/>
						</div>
					</div>);
					cols.push(<div className="p-2 text-dark" key={`stocktype_dependent33-${vehicle_column.id}`}>
						<p className="text-dark">
							<i className={'fa fa-info-circle text-dark'} />
							{' '}
The Required Department/Stage/Queue/Bucket the Wholesale vehicle must be in to automove.
						</p>
						<div className="form-group">
							<label className="control-label bg-dark text-white"># Required Current Department -- Wholesale</label>
							<Dropdown
								titleHelper={''}
								title={'Choose Required Current Department'}
								value={vehicle_column.current_department_wholesale}
								name={'current_department_wholesale'}
								vehicle_column_id={vehicle_column.id}
								update_type={'vehicle_column'}
								className={'form-control'}
								list={this.automoveDestinations(vehicle_column.current_department_wholesale)}
								update_type={'vehicle_column'}
							/>
						</div>
					</div>);
				} else {
					cols.push(<div className="p-2 text-dark" key={`stocktype_dependent44-${vehicle_column.id}`}>
						<p className="text-dark">
							<i className={'fa fa-info-circle text-dark'} />
							{' '}
The Required Department/Stage/Queue/Bucket the vehicle must be in to automove.
						</p>
						<div className="form-group">
							<label className="control-label bg-dark text-white"># Required Current Department</label>
							<Dropdown
								titleHelper={''}
								title={'Choose Required Current Department'}
								value={vehicle_column.current_department}
								name={'current_department'}
								vehicle_column_id={vehicle_column.id}
								update_type={'vehicle_column'}
								className={'form-control'}
								list={this.automoveDestinations(vehicle_column.current_department)}
								update_type={'vehicle_column'}
							/>
						</div>
					</div>);
				}
			}

			cols.push(<div className="card" key={`stocktype_dependent55-${vehicle_column.id}`}>
				<div className="d-flex flex-column pad-left-2 pad-right-2">
					{destinations}
				</div>
			</div>);
		}
		return (
			<div className="container pad-bottom-2 edit-vehicle-column-page bg-dark" key={`stocktype_dependent66-${vehicle_column.id}`}>
				{cols}
			</div>
		);
	}
}

const mappedActions = {
	addVehicleColumnOption,
	updateVehicleColumn,
	setCurrentTab,
};

function mapStateToProps(state) {
	return {
		departments: state.settings_departments.original_departments,
		input_types: state.settings_departments.input_types,
		vehicle_columns: state.settings_departments.vehicle_columns,
		all_filters: state.settings_site.all_filters,
		set_dealerships: state.settings_dealerships.related_installations,
		vehicle_column: state.settings_admin.edit_vehicle_column,
	};
}

export default connect(mapStateToProps, mappedActions)(TabEditVehicleColumn);
