import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { userStamp } from '../../../../utils/dateFunctions';
import { pushModal } from '../../../../actions/modals';
import { ATTACHMENT_MODAL } from '../../../../actions/types';
import getMediaType from '../../../../utils/mediaTypes';

class FileAttachmentSingle extends PureComponent {
	constructor(props) {
		super(props);

		this.state = { addDepartmentPropertyError: '', imageVisible: false, invalidFileType: false };
		this.onClick = this.onClick.bind(this);
		this.getfiletype = this.getfiletype.bind(this);
		this.triggerModal = this.triggerModal.bind(this);
		this.showImage = this.showImage.bind(this);
		this.onPreview = this.onPreview.bind(this);
		this.triggerInvalidFileMessage = this.triggerInvalidFileMessage.bind(this);
		this.saveAsBlob = this.saveAsBlob.bind(this);
		this.downloadFile = this.downloadFile.bind(this);
	}

	getfiletype() {
		const file = this.props.attachment.fileid;
		const filetype = file.split('.').pop();
		let icon = '';
		if (filetype === 'pdf') {
			icon = 'fa-file-pdf';
		} else if (filetype === 'csv' || filetype === 'xls' || filetype === 'xlsx') {
			icon = 'fa-file-excel';
		} else if (filetype === 'jpg' || filetype === 'jpeg' || filetype === 'png' || filetype === 'gif') {
			icon = 'fa-image';
		} else if (filetype === 'doc' || filetype === 'docx') {
			icon = 'fa-file-word'
		} else if (filetype === 'txt') {
			icon = 'fa-file-lines';
		} else {
			icon = 'fa-file';
		}
		return icon;
	}

	onClick() {
		this.props.delete(this.props.attachment.id);
	}

	onPreview() {
		const fileType = this.getfiletype();
		if (fileType === 'fa-image') this.showImage();
		else if (fileType === 'fa-file-pdf') this.triggerModal();
		else this.triggerInvalidFileMessage();
	}

	triggerModal = () => this.props.pushModal({
		type: ATTACHMENT_MODAL,
		props: {
			title: this.props.attachment.fileid,
			attachment: this.props.attachment,
		},
	});

	showImage = () => {
		this.setState({ imageVisible: !this.state.imageVisible });
	};

	triggerInvalidFileMessage = () => {
		this.setState({ invalidFileType: !this.state.invalidFileType });
	};

	saveAsBlob = async fileUrl => {
		const url = fileUrl
		const response = await fetch(url);
		const blob = await response.blob();
		return blob;
	};

	downloadFile = async () => {
		const blob = await this.saveAsBlob(this.props.attachment.location);
		const mediaType = getMediaType(this.props.attachment.fileid);
		const downloadBlob = new Blob([blob], { type: mediaType });
		const data = window.URL.createObjectURL(downloadBlob);
		const link = document.createElement('a');
		link.href = data;
		link.setAttribute('download', this.props.attachment.fileid.split('.').shift());
		document.body.appendChild(link);
		link.click();
		setTimeout(() => {
			document.body.removeChild(link);
			window.URL.revokeObjectURL(data);
		}, 100);
	};

	render() {
		const createdDate = userStamp(this.props.attachment.created_at);
		const fileType = this.getfiletype();
		let imagePreview = '';
		if (fileType === 'fa-image') {
			imagePreview = (
			<div className="container">
				<img src={this.props.attachment.location} className="img-fluid" alt="Attachment Preview" style={{maxWidth: "500px"}} />
			</div>);
		}
		let imagePreviewText = this.state.imageVisible ? 'Hide Image' : 'Show Image';
		let previewMessage = "Unable to preview this file type.";

		return (
			<li className="list-group-item">
				<small className="d-flex justify-content-end">Uploaded on: {createdDate}</small>
				<div className='d-flex justify-content-between mt-2'>
					<h5 className="display-10 float-left">{this.props.attachment.fileid}</h5>
					<span className="pt-1 pb-3 float-right">
						<button type='button' onClick={this.onPreview} className="btn btn-sm btn-dark space-right-2">
							{fileType === 'fa-image' ? imagePreviewText : 'Preview'} <i className={`fas ${fileType}`} />
						</button>
						<button onClick={this.downloadFile} className="btn btn-sm btn-info text-light">
							Download <i className={'fas fa-cloud-download'} style={{color: "white"}} />
						</button>
						<button onClick={this.onClick} className="btn btn-sm btn-danger float-right space-left-2">
							Delete <i className={'fas fa-trash'} />
						</button>
					</span>
				</div>
				{
					this.props.attachment.user_name ?
					<div><small>Uploaded by: {this.props.attachment.user_name.split('@')[0]}</small></div> : ''
				}
				{this.state.imageVisible ? imagePreview : ''}
				{this.state.invalidFileType ? <div className="text-center">{previewMessage}</div> : ''}
			</li>
		);
	}
}

const mappedActions = {
	pushModal,
};

function mapStateToProps(state) {
	return {
		current_dealership: state.settings_dealerships.current_dealership,
		active_vehicle: state.settings_refresh.active_vehicle,
	};
}

export default connect(mapStateToProps, mappedActions)(FileAttachmentSingle);
