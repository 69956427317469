
export default function getMediaType(file) {
    const fileType = file.split('.').pop();

    const mediaTypes = {
        pdf: "application/pdf",
        jpg: "image/jpeg",
        jpeg: "image/jpeg",
        png: "image/png",
        gif: "image/gif",
        bmp: "image/bmp",
        svg: "image/svg",
        txt: "text/plain",
        xls: "application/vnd.ms-excel",
        xlsx: "application/vnd.ms-excel",
        csv: "application/vnd.ms-excel",
        doc: "application/msword",
        docx: "application/msword",
        rtf: "application/msword",
        xlsm: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        xltx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        mp4: "video/mp4",
        mov: "video/quicktime",
        txt: "text/plain",
        mp3: "audio/mpeg",
        wav: "audio/wav",
        flac: "audio/flac",
    };

    if (Object.hasOwn(mediaTypes, fileType)) {
        return mediaTypes[fileType];
    }

    return null
};