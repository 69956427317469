import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import domtoimage from 'dom-to-image';
import jsPDF from 'jspdf';
import AllInputs from '../../../../inputs/AllInputs';

const Inspection = (props) => {
  const dispatch = useDispatch();

  const current_dealership = useSelector((state) => state.settings_dealerships.current_dealership);
  const active_vehicle = useSelector((state) => state.settings_refresh.active_vehicle);

  const [state, setState] = useState({});

  useEffect(() => {
    let topRows = ['custom_inspection1', 'custom_inspection2', 'custom_inspection3', 'custom_inspection4'];
    let firstRows = [
      'custom_inspection1',
      'custom_inspection2',
      'custom_inspection3',
      'custom_inspection4',
      'Tread Depth Front',
      'Tread Depth Rear',
      'Pad Life Front',
      'Pad Life Rear',
      'Rotors',
      'Suspension',
      'Axle/Bools/U Joints',
      'Steering Components',
      'Shocks/Strusts',
      'Oil Leaks',
      'Tranny Leaks/Flush',
      'Exaust',
      'Power Steering',
      'Filters',
      'Chassis Damage',
      "Owner's Manual",
      'Lube Doors/Hinges',
      'Jack Assembly',
      'Spare Tire',
      'Engine Operation',
      'Cruise',
      'Alignment',
      'Steering Wheel Center',
      'Interior Lights',
      'Power Accessories',
      'A/C & Heater',
      'Seat Belts',
      'Keys/Fobs',
    ];
    let fields = ['Pass', 'Recommend', 'Required', 'Notes', 'Labor Hours', 'Parts', 'Total', 'Completed'];

    if (current_dealership === 'integrity-autos') {
      topRows = [];
      fields = ['Pass', 'Recommend', 'Required', 'Notes', 'Labor Hours', 'Parts', 'Total', 'Completed'];
      firstRows = [
        'Pictures',
        'Oil / Coolant Condition',
        'Motor Mounts Vibration',
        'Engine',
        'Transmission Shifting',
        'Wheel Alignment',
        'Brakes/A.B.S',
        'Suspension',
        'Cruise',
        'Sound System | Display',
        'AC / Heater',
        'Horn',
        'Sunroof Operation (ARB)',
        'Power Windows',
        'Power Mirrors',
        'Front / Rear Wiper Blades',
        '4X4 / AWD',
        'Emergency Parking Brake',
        'Road Test',
        'Exhaust System / Cats (ARB)',
        'Frame (ARB)',
        'Motor Belts',
        'Fluid Leaks',
        'Brake Pads / Rotors',
        'Wheels / Tires / Lug Nuts',
        'Aftermarket Equipment',
        'Cold Start / Battery',
        'Walk Around / Pictures',
        'AIR-BAGS (ARB)',
        'PDR HOLES (ARB)',
        'Seat Belts (ARB)',
        'Spare Tire & Jack',
        'Body Damage',
        'Glass / Windows',
        'Run for Computer',
        'Diagnostic',
        'Dashboard Lights',
        'Monitors',
        'Interior Lights',
        'Door | Trunk Locks',
        'Power Seats',
        'Headlights | Tail Lights',
        'Brake Lights | Hazard',
        'Flasher | License Plate Lights',
        'Cabin Air Filter / Smells',
        'Interior Damages',
      ];
    }
  }, [current_dealership]); // Dependency on current_dealership

  // Event handler for input changes
  const onChange = (e) => {
    setState((prevState) => ({ ...prevState, [e.target.name]: e.target.value }));
  };

  // Utility function to get vehicle property
  const vehicleProperty = (name) => {
    return _.find(active_vehicle.vehicle_properties, { key: name }) || '';
  };

  // Print handler
  const onPrint = () => {
    const print_page = document.getElementById('print-inspection');
    domtoimage
      .toPng(print_page)
      .then((canvasOrDataUrl) => {
        if (typeof canvasOrDataUrl === 'string') {
          const pdf = new jsPDF();
          pdf.addImage(canvasOrDataUrl, 'PNG', 20, 5, 175, 290);
          pdf.save('vehicle_inspection.pdf');
        } else {
          document.body.appendChild(canvasOrDataUrl);
        }
      })
      .catch((error) => {
        console.log('oops, something went wrong!', error);
      });
  };

  // Generate rows
  const rows = [];
  const leftAndRight = ['Tread Depth Front', 'Tread Depth Rear', 'Pad Life Front', 'Pad Life Rear'];

  let topRows = ['custom_inspection1', 'custom_inspection2', 'custom_inspection3', 'custom_inspection4'];
  let firstRows = [
    'custom_inspection1',
    'custom_inspection2',
    'custom_inspection3',
    'custom_inspection4',
    'Tread Depth Front',
    'Tread Depth Rear',
    'Pad Life Front',
    'Pad Life Rear',
    'Rotors',
    'Suspension',
    'Axle/Bools/U Joints',
    'Steering Components',
    'Shocks/Strusts',
    'Oil Leaks',
    'Tranny Leaks/Flush',
    'Exaust',
    'Power Steering',
    'Filters',
    'Chassis Damage',
    "Owner's Manual",
    'Lube Doors/Hinges',
    'Jack Assembly',
    'Spare Tire',
    'Engine Operation',
    'Cruise',
    'Alignment',
    'Steering Wheel Center',
    'Interior Lights',
    'Power Accessories',
    'A/C & Heater',
    'Seat Belts',
    'Keys/Fobs',
  ];
  let fields = ['Pass', 'Recommend', 'Required', 'Notes', 'Labor Hours', 'Parts', 'Total', 'Completed'];

  // Apply conditional changes based on the latest useEffect
  if (current_dealership === 'integrity-autos') {
    topRows = [];
    fields = ['Pass', 'Required', 'Notes', 'Labor Hours', 'Parts', 'Total', 'Completed'];
    firstRows = [
      'Pictures',
      'Oil / Coolant Condition',
      'Motor Mounts Vibration',
      'Engine',
      'Transmission Shifting',
      'Wheel Alignment',
      'Brakes/A.B.S',
      'Suspension',
      'Cruise',
      'Sound System | Display',
      'AC / Heater',
      'Horn',
      'Sunroof Operation (ARB)',
      'Power Windows',
      'Power Mirrors',
      'Front / Rear Wiper Blades',
      '4X4 / AWD',
      'Emergency Parking Brake',
      'Road Test',
      'Exhaust System / Cats (ARB)',
      'Frame (ARB)',
      'Motor Belts',
      'Fluid Leaks',
      'Brake Pads / Rotors',
      'Wheels / Tires / Lug Nuts',
      'Aftermarket Equipment',
      'Cold Start / Battery',
      'Walk Around / Pictures',
      'AIR-BAGS (ARB)',
      'PDR HOLES (ARB)',
      'Seat Belts (ARB)',
      'Spare Tire & Jack',
      'Body Damage',
      'Glass / Windows',
      'Run for Computer',
      'Diagnostic',
      'Dashboard Lights',
      'Monitors',
      'Interior Lights',
      'Door | Trunk Locks',
      'Power Seats',
      'Headlights | Tail Lights',
      'Brake Lights | Hazard',
      'Flasher | License Plate Lights',
      'Cabin Air Filter / Smells',
      'Interior Damages',
    ];
  }

  firstRows.forEach((row) => {
    const pass = `${row}_Pass`;
    const recommend = `${row}_Recommend`;
    const required_name = `${row}_Required`;
    const notes = `${row}_Notes`;
    const labor_hours = `${row}_Labor Hours`;
    const parts = `${row}_Parts`;
    const total = `${row}_Total`;
    const completed = `${row}_Completed`;
    const setdisabled = false;

    const custom_1 = vehicleProperty('custom_inspection1') || '';
    const custom_2 = vehicleProperty('custom_inspection2') || '';
    const custom_3 = vehicleProperty('custom_inspection3') || '';
    const custom_4 = vehicleProperty('custom_inspection4') || '';

    const tread_depth_front_left = vehicleProperty('Tread Depth Front_left') || '';
    const tread_depth_front_right = vehicleProperty('Tread Depth Front_right') || '';
    const tread_depth_rear_left = vehicleProperty('Tread Depth Rear_left') || '';
    const tread_depth_rear_right = vehicleProperty('Tread Depth Rear_right') || '';

    const pad_life_front_left = vehicleProperty('Pad Life Front_left') || '';
    const pad_life_front_right = vehicleProperty('Pad Life Front_right') || '';
    const pad_life_rear_left = vehicleProperty('Pad Life Rear_left') || '';
    const pad_life_rear_right = vehicleProperty('Pad Life Rear_right') || '';

    const pass_colObject = vehicleProperty(pass) || '';
    const recommend_colObject = vehicleProperty(recommend) || '';
    const required_name_colObject = vehicleProperty(required_name) || '';
    const notes_colObject = vehicleProperty(notes) || '';
    const labor_hours_colObject = vehicleProperty(labor_hours) || '';
    const parts_colObject = vehicleProperty(parts) || '';
    const total_colObject = vehicleProperty(total) || '';
    const completed_colObject = vehicleProperty(completed) || '';

    const label = row;
    const firstrow = [];

    if (row.includes('custom')) {
      if (row === 'custom_inspection1') {
        firstrow.push(
          <AllInputs
            key={pass}
            value={custom_1.value || ''}
            type={'text'}
            name={'custom_inspection1'}
            parentid={active_vehicle.id}
            updatetype="vehicleproperty"
            setdisabled={setdisabled}
            user_name={custom_1.user_name}
            updated_at={custom_1.updated_at}
            vehicle={active_vehicle}
          />
        );
      } else if (row === 'custom_inspection2') {
        firstrow.push(
          <AllInputs
            key={pass}
            value={custom_2.value || ''}
            type={'text'}
            name={'custom_inspection2'}
            parentid={active_vehicle.id}
            updatetype="vehicleproperty"
            setdisabled={setdisabled}
            user_name={custom_2.user_name}
            updated_at={custom_2.updated_at}
            vehicle={active_vehicle}
          />
        );
      } else if (row === 'custom_inspection3') {
        firstrow.push(
          <AllInputs
            key={pass}
            value={custom_3.value || ''}
            type={'text'}
            name={'custom_inspection3'}
            parentid={active_vehicle.id}
            updatetype="vehicleproperty"
            setdisabled={setdisabled}
            user_name={custom_3.user_name}
            updated_at={custom_3.updated_at}
            vehicle={active_vehicle}
          />
        );
      } else if (row === 'custom_inspection4') {
        firstrow.push(
          <AllInputs
            key={pass}
            value={custom_4.value || ''}
            type={'text'}
            name={'custom_inspection4'}
            parentid={active_vehicle.id}
            updatetype="vehicleproperty"
            setdisabled={setdisabled}
            user_name={custom_4.user_name}
            updated_at={custom_4.updated_at}
            vehicle={active_vehicle}
          />
        );
      }
    }

    if (row.includes('Tread') || row.includes('Pad')) {
      if (row === 'Tread Depth Front') {
        firstrow.push(
          <div key={pass} className="row">
            <div className="col-md-12">{row}</div>
            <div className="col-md-6">
              <AllInputs
                value={tread_depth_front_left.value || ''}
                type={'text'}
                name={'Tread Depth Front_left'}
                parentid={active_vehicle.id}
                updatetype="vehicleproperty"
                setdisabled={setdisabled}
                user_name={tread_depth_front_left.user_name}
                updated_at={tread_depth_front_left.updated_at}
                vehicle={active_vehicle}
              />
            </div>
            <div className="col-md-6">
              <AllInputs
                value={tread_depth_front_right.value || ''}
                type={'text'}
                name={'Tread Depth Front_right'}
                parentid={active_vehicle.id}
                updatetype="vehicleproperty"
                setdisabled={setdisabled}
                user_name={tread_depth_front_right.user_name}
                updated_at={tread_depth_front_right.updated_at}
                vehicle={active_vehicle}
              />
            </div>
          </div>
        );
      } else if (row === 'Tread Depth Rear') {
        firstrow.push(
          <div key={pass} className="row">
            <div className="col-md-12">{row}</div>
            <div className="col-md-6">
              <AllInputs
                value={tread_depth_rear_left.value || ''}
                type={'text'}
                name={'Tread Depth Rear_left'}
                parentid={active_vehicle.id}
                updatetype="vehicleproperty"
                setdisabled={setdisabled}
                user_name={tread_depth_rear_left.user_name}
                updated_at={tread_depth_rear_left.updated_at}
                vehicle={active_vehicle}
              />
            </div>
            <div className="col-md-6">
              <AllInputs
                value={tread_depth_rear_right.value || ''}
                type={'text'}
                name={'Tread Depth Rear_right'}
                parentid={active_vehicle.id}
                updatetype="vehicleproperty"
                setdisabled={setdisabled}
                user_name={tread_depth_rear_right.user_name}
                updated_at={tread_depth_rear_right.updated_at}
                vehicle={active_vehicle}
              />
            </div>
          </div>
        );
      } else if (row === 'Pad Life Front') {
        firstrow.push(
          <div key={pass} className="row">
            <div className="col-md-12">{row}</div>
            <div className="col-md-6">
              <AllInputs
                value={pad_life_front_left.value || ''}
                type={'text'}
                name={'Pad Life Front_left'}
                parentid={active_vehicle.id}
                updatetype="vehicleproperty"
                setdisabled={setdisabled}
                user_name={pad_life_front_left.user_name}
                updated_at={pad_life_front_left.updated_at}
                vehicle={active_vehicle}
              />
            </div>
            <div className="col-md-6">
              <AllInputs
                value={pad_life_front_right.value || ''}
                type={'text'}
                name={'Pad Life Front_right'}
                parentid={active_vehicle.id}
                updatetype="vehicleproperty"
                setdisabled={setdisabled}
                user_name={pad_life_front_right.user_name}
                updated_at={pad_life_front_right.updated_at}
                vehicle={active_vehicle}
              />
            </div>
          </div>
        );
      } else if (row === 'Pad Life Rear') {
        firstrow.push(
          <div key={pass} className="row">
            <div className="col-md-12">{row}</div>
            <div className="col-md-6">
              <AllInputs
                value={pad_life_rear_left.value || ''}
                type={'text'}
                name={'Pad Life Rear_left'}
                parentid={active_vehicle.id}
                updatetype="vehicleproperty"
                setdisabled={setdisabled}
                user_name={pad_life_rear_left.user_name}
                updated_at={pad_life_rear_left.updated_at}
                vehicle={active_vehicle}
              />
            </div>
            <div className="col-md-6">
              <AllInputs
                value={pad_life_rear_right.value || ''}
                type={'text'}
                name={'Pad Life Rear_right'}
                parentid={active_vehicle.id}
                updatetype="vehicleproperty"
                setdisabled={setdisabled}
                user_name={pad_life_rear_right.user_name}
                updated_at={pad_life_rear_right.updated_at}
                vehicle={active_vehicle}
              />
            </div>
          </div>
        );
      }
    }

    rows.push(
      <tr key={pass}>
        <th className="text-uppercase">{firstrow.length > 0 ? firstrow : label}</th>
        <td className={pass_colObject.value === 'true' ? 'bg-green text-center' : 'text-center'}>
          <AllInputs
            value={pass_colObject.value || ''}
            type={'userstamp'}
            name={pass}
            parentid={active_vehicle.id}
            updatetype="vehicleproperty"
            setdisabled={setdisabled}
            user_name={pass_colObject.user_name}
            updated_at={pass_colObject.updated_at}
            vehicle={active_vehicle}
          />
        </td>
        <td className={recommend_colObject.value === 'true' ? 'bg-yellow text-center' : 'text-center'}>
          <AllInputs
            value={recommend_colObject.value || ''}
            type={'userstamp'}
            name={recommend}
            parentid={active_vehicle.id}
            updatetype="vehicleproperty"
            setdisabled={setdisabled}
            user_name={recommend_colObject.user_name}
            updated_at={recommend_colObject.updated_at}
            vehicle={active_vehicle}
          />
        </td>
        <td className={required_name_colObject.value === 'true' ? 'bg-red text-center' : 'text-center'}>
          <AllInputs
            value={required_name_colObject.value || ''}
            type={'userstamp'}
            name={required_name}
            parentid={active_vehicle.id}
            updatetype="vehicleproperty"
            setdisabled={setdisabled}
            user_name={required_name_colObject.user_name}
            updated_at={required_name_colObject.updated_at}
            vehicle={active_vehicle}
          />
        </td>
        <td>
          <AllInputs
            value={notes_colObject.value || ''}
            type={'textarea'}
            name={notes}
            parentid={active_vehicle.id}
            updatetype="vehicleproperty"
            setdisabled={setdisabled}
            user_name={notes_colObject.user_name}
            updated_at={notes_colObject.updated_at}
            vehicle={active_vehicle}
          />
        </td>
        <td>
          <AllInputs
            value={labor_hours_colObject.value || ''}
            type={'text'}
            name={labor_hours}
            parentid={active_vehicle.id}
            updatetype="vehicleproperty"
            setdisabled={setdisabled}
            user_name={labor_hours_colObject.user_name}
            updated_at={labor_hours_colObject.updated_at}
            vehicle={active_vehicle}
          />
        </td>
        <td>
          <AllInputs
            value={parts_colObject.value || ''}
            type={'text'}
            name={parts}
            parentid={active_vehicle.id}
            updatetype="vehicleproperty"
            setdisabled={setdisabled}
            user_name={parts_colObject.user_name}
            updated_at={parts_colObject.updated_at}
            vehicle={active_vehicle}
          />
        </td>
        <td>
          <AllInputs
            value={total_colObject.value || ''}
            type={'text'}
            name={total}
            parentid={active_vehicle.id}
            updatetype="vehicleproperty"
            setdisabled={setdisabled}
            user_name={total_colObject.user_name}
            updated_at={total_colObject.updated_at}
            vehicle={active_vehicle}
          />
        </td>
        <td className={completed_colObject.value === 'true' ? 'bg-green text-center' : 'text-center'}>
          <AllInputs
            value={completed_colObject.value || ''}
            type={'userstamp'}
            name={completed}
            parentid={active_vehicle.id}
            updatetype="vehicleproperty"
            setdisabled={setdisabled}
            user_name={completed_colObject.user_name}
            updated_at={completed_colObject.updated_at}
            vehicle={active_vehicle}
          />
        </td>
      </tr>
    );
  });

  return (
    <>
      <button onClick={onPrint} className="btn btn-success float-left">
        Print
      </button>

      <div className="container-fluid" id="print-inspection">
        <div className="row">
          <div className="col-xs-12 column no-more-tables ui-grid-a">
            <h3>
              <small className="float-left space-left-2 pad-left-2">
                {active_vehicle.year} {active_vehicle.make} {active_vehicle.model} ({active_vehicle.stock})
              </small>
            </h3>
            <table
              className="col-xs-12 table-bordered table-striped inspection-table"
              style={{ width: '94%', margin: 'auto' }}
            >
              <thead className="thead-light">
                <tr>
                  <th className="text-center col-xs-2" style={{ width: '200px' }} />
                  <th className="text-center col-xs-1" style={{ width: '90px' }}>
                    Pass
                  </th>
                  <th className="text-center col-xs-1" style={{ width: '90px' }}>
                    Recommend
                  </th>
                  <th className="text-center col-xs-1" style={{ width: '90px' }}>
                    Required
                  </th>
                  <th className="text-center col-xs-2" style={{ width: '180px' }}>
                    Notes
                  </th>
                  <th className="text-center col-xs-1" style={{ width: '45px' }}>
                    Labor Hours
                  </th>
                  <th className="text-center col-xs-1" style={{ width: '45px' }}>
                    Parts
                  </th>
                  <th className="text-center col-xs-1" style={{ width: '45px' }}>
                    Total
                  </th>
                  <th className="text-center col-xs-1" style={{ width: '90px' }}>
                    Completed
                  </th>
                </tr>
              </thead>
              <tbody className="detail_slip_form">{rows}</tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default Inspection;