import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios, { post } from 'axios';
import ReactDropzone from 'react-dropzone';
import {
	addAttachment,
} from '../../actions/apiAttachments';

class DropBox extends Component {
	constructor(props) {
		super(props);

		this.state = {
			value: this.props.value || '', 
			checked: this.props.checked || '', 
			file: '', 
			image: '', 
			image_name: '', 
			image_type: '', 
			mage_size: '', 
			files: [],
			loading: false,
			progress: 0,
		};
		this.onClick = this.onClick.bind(this);
		this.onClickDelete = this.onClickDelete.bind(this);
		this.onDrop = this.onDrop.bind(this);
	}

	onDrop(files) {
		this.setState({
			files: files,
			loading: true,
		});
		const currentProgress = files.map((file) => {
			this.fileUpload(file);
			return 1 / files.length * 100;
			//this.setState({ progress: this.state.progress + currentProgress });
		});
		setInterval(() => this.setState({ progress: this.state.progress + currentProgress.shift() }), 750);
		setTimeout(() => clearInterval(), 800 * currentProgress.length);
		setTimeout(() => this.setState({ loading: false }), (850 * currentProgress.length + 2000));
	}

	onClick() {
		const { files } = this.state;

		files.map((file) => {
			this.fileUpload(file);
		});
		this.setState({
			files: [],
		});
	}

	onClickDelete(name) {
		const newFiles = this.state.files.filter(el => el.name !== name);
		this.setState({
			files: newFiles || [],
		});
	}

	uploadData(response, fileid) {
		const fileurl = response.data;
		const errorType = 'addAttachmentError';
		const { vid } = this.props;
		const { siteid } = this.props;
		const { user_name } = this.props;

		const data = {
			vehicle_id: vid,
			filename: fileid,
			location: fileurl,
			fileid,
			siteid,
			user_name,
		};

		this.props.addAttachment(data, errorType);
	}

	fileUpload(file) {
		const url = "https://gotoreconfiles.com/cors_new_server.php";

		const self = this;
		const { siteid } = self.props;
		const fileid = file.name;
		const data = new FormData();
		data.append('data', file);
		data.append('action', 'put-newserver-file');
		data.append('fileid', fileid);
		data.append('siteid', siteid);

		return axios.post(url, data, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},

		}).then(response => this.uploadData(response, fileid));
	}

	render() {
		return (
			<div>
				<div className="dropzone">

					<ReactDropzone className="drop-box p-2 text-center space-top-1 btn btn-dark form-control" onDrop={this.onDrop}>
						{({ getRootProps, getInputProps }) => (
							<section>
								<div {...getRootProps()}>
									<input {...getInputProps()} />
									<div className="card-header bg-grey text-white text-capitalize">
										<button className="btn btn-dark form-control">Drop/Add Files</button>
									</div>
								</div>
							</section>
						)}
					</ReactDropzone>
				</div>
				<aside>
					<div>
						{
							this.state.loading &&
							<div class="progress" role="progressbar" aria-label="Success example" aria-valuenow={this.state.progress} aria-valuemin="0" aria-valuemax="100">
								<div class="progress-bar" id="dropbox-progress" style={{ width: `${this.state.progress}%` }}>
									{this.state.progress > 0 && this.state.progress < 100 && "Uploading..."}
									{this.state.progress === 100 && "Complete!"}
								</div>
							</div>
						}
					</div>

				</aside>
			</div>
		);
	}
}

const mappedActions = {
	addAttachment,
};

function mapStateToProps(state) {
	return { user_name: state.current_user.user_name };
}

export default connect(mapStateToProps, mappedActions)(DropBox);
