import React from "react";
import { ModalContainer } from "./modalContainer";
import PDFviewer from "../components/PDFviewer/pdfViewer";


export default function PreviewAttachment(props) {
    const { currentProps, defaultProps } = props;
    const { title, attachment } = currentProps;
    const fileType = attachment.fileid.split('.').pop();

    const contentType = fileType => {
        if (fileType === 'jpg' || fileType === 'jpeg' || fileType === 'png' || fileType === 'gif') {
            return 'img';
        }
        if (fileType === 'pdf') {
            return 'pdf';
        }
        return 'frame';
    }

    if (contentType(fileType) === 'img') {
        return (
            <ModalContainer title={title} {...defaultProps}>
                <div className="container">
                    <img src={attachment.location} className="img-fluid" alt="Attachment Preview" />
                </div>
            </ModalContainer>
        )
    }

    if (contentType(fileType) === 'pdf' || fileType === 'csv') {
        return (
            <ModalContainer title={title} {...defaultProps}>
                <PDFviewer fileName={attachment.fileid} url={attachment.location} />
            </ModalContainer>
        )
    }

    return (
        <ModalContainer title={title} {...defaultProps}>
            <div className="container">
                <ModuleiFrame module={attachment} />
            </div>
        </ModalContainer>
    )
}

function ModuleiFrame(props){
    let {module} = props
    return(
      <div className="module-iframe">
        <iframe
          src={module.location}
          key={module.fileid}
          view="Fit"
          aria-label={`${module.fileid}-pdf-img`}
          allowFullScreen
        />
      </div>
    )
}
