import React, { Component, useState, useMemo, useRef } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { MaterialReactTable } from 'material-react-table';
import Select from 'react-select';
import {
 Box, Button, createTheme, ThemeProvider, useTheme, MenuItem, ListItemIcon,
} from '@mui/material';
import { AccountCircle, Send } from '@mui/icons-material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import AllInputs from '../../../../inputs/AllInputs';
import {
 getDateTime, readableStamp,
} from '../../../../utils/dateFunctions';
import TextFieldGroup from '../../../../inputs/TextFieldGroup';
import {
	appraisalRequestNotification,
	appraisalNotification,
} from '../../../../actions/apiNotifications';
import {
	lookupUserById,
	lookupDepartmentById,
	lookupDepartmentByName,
	lookupVehicleEstimateById,
	vsearch,
  lookupRecipients,
} from '../../../../actions/lookups';

import {
	addEstimate, sendEstimate, deleteEstimate,
} from '../../../../actions/apiEstimates';

const NewEstimates = (props) => {
  const [selectedItems, setSelectedItems] = useState([]); // items selected to be either bulk approved or denied
  const [confirmingTask, setConfirmingTask] = useState(null); // task to be confirmed before bulk action
  const tableRef = useRef();
  const isDisabled = props.current_user.user ? !(props.current_user.user.approve_estimates || props.work_item_permissions || props.current_user.position === 'admin' || props.current_user.position === 'super_admin') : true;

  const columns = [
    {
      accessorKey: 'category',
      header: 'Classification',
      muiTableHeadCellProps: {
        style: {
          padding: '0.75rem 0rem 0.6rem 0.5rem',
        }
      },
      enableEditing: false,
      size: 90,
    },
    {
      accessorKey: 'label_item_1',
      header: 'Work Item',
      size: 160,
      muiTableHeadCellProps: {
        style: {
          paddingTop: '0.75rem',
        }
      },
      enableEditing: true,
      muiEditTextFieldProps: ({ cell, row, table }) => ({
        disabled: row.original.lock_estimate[0],
        onBlur: (event) => {
          const data = {
            vehicle_id: props.active_vehicle.id,
            label_item_1: event.target.value,
          };
          props.sendEstimate(row.original.estimate_id[0], data, 'addEstimateError');
        },
      }),
      Cell: ({ renderedCellValue, row }) => (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '1rem',
          }}
        >
          {row.original.estimate_type[0] == 'blank_parts_and_labor' ? renderedCellValue : <Select
              titleHelper={'Type'}
              title={'Choose'}
              name={'WorkItem'}
              value={selectWorkItem().filter(({ value }) => value === row.original.label_item_1[0])}
              options={selectWorkItem()}
              clearable={false}
              searchable
              menuPortalTarget={document.body}
              menuPosition={'fixed'}
              className="text-capitalize"
              isDisabled={row.original.lock_estimate[0]}
              vehicle={props.active_vehicle.vehicle_id}
              onChange={onChangeWorkItem(row.original.estimate_id[0])}
              styles={{
                container: (base) => ({
                  ...base,
                  width: '100%',
                }),
              }}
          />}
        </Box>
      ),
    },
    {
      accessorKey: 'description',
      header: 'Description',
      size: 25,
      muiTableHeadCellProps: {
        style: {
          padding: '0.75rem 0rem 0.6rem 0.5rem',
        }
      },
      enableEditing: true,
      muiEditTextFieldProps: ({ cell, row, table }) => ({
        disabled: row.original.ro_status[0] === 'approved',
        onBlur: (event) => {
          const data = {
            vehicle_id: props.active_vehicle.id,
            description: event.target.value,
          };
          props.sendEstimate(row.original.estimate_id[0], data, 'addEstimateError');
        },
      }),
    },
    {
      accessorKey: 'parts_price',
      header: 'Parts',
      size: 25,
      muiTableHeadCellProps: {
        style: {
          padding: '0.75rem 0rem 0.6rem 0.5rem',
        }
      },
      enableEditing: true,
      muiEditTextFieldProps: ({ cell, row, table }) => ({
        disabled: row.original.ro_status[0] === 'pending' ? !row.original.lock_item_1 : row.original.lock_estimate[0],
        onBlur: (event) => {
          var estimate_total = Number(event.target.value) + Number(row.original.labor_price[0]);
          const data = {
            vehicle_id: props.active_vehicle.id,
            parts_price: event.target.value,
            estimate_total,
            estimate: estimate_total,
          };
          props.sendEstimate(row.original.estimate_id[0], data, 'addEstimateError');
        },
      }),
    },
    {
      accessorKey: 'labor_units',
      header: 'Labor Units',
      size: 25,
      muiTableHeadCellProps: {
        style: {
          padding: '0.75rem 0rem 0.6rem 0.5rem',
        }
      },
      enableEditing: true,
      muiEditTextFieldProps: ({ cell, row, table }) => ({
        disabled: row.original.ro_status[0] === 'pending' ? !row.original.lock_item_2 : row.original.lock_estimate[0],
        onBlur: (event) => {
          var default_labor_rate = _.find(props.dealer_settings, o => o.key === 'default_labor_rate' && o.dealership === props.installation.subdomain);
          var default_labor_rate_value = default_labor_rate ? default_labor_rate.value : 100;
          var labor_price = Number(event.target.value) * Number(default_labor_rate_value);
          var estimate_total = Number(row.original.parts_price[0]) + Number(labor_price);

          const data = {
            vehicle_id: props.active_vehicle.id,
            labor_units: event.target.value,
            estimate_total,
            labor_price,
          };
          props.sendEstimate(row.original.estimate_id[0], data, 'addEstimateError');
        },
      }),
    },
    {
      accessorKey: 'labor_price',
      header: 'Labor',
      size: 25,
      muiTableHeadCellProps: {
        style: {
          padding: '0.75rem 0rem 0.6rem 0.5rem',
        }
      },
      enableEditing: true,
      muiEditTextFieldProps: ({ cell, row, table }) => ({
        disabled: row.original.ro_status[0] === 'pending' ? !row.original.lock_item_3 : row.original.lock_estimate[0],
        onBlur: (event) => {
          var estimate_total = Number(event.target.value) + Number(row.original.parts_price[0]);
          const data = {
            vehicle_id: props.active_vehicle.id,
            labor_price: event.target.value,
            estimate_total,
            estimate: estimate_total,
          };
          props.sendEstimate(row.original.estimate_id[0], data, 'addEstimateError');
        },
      }),
    },
    {
      accessorKey: 'estimate_total',
      header: 'Total',
      size: 25,
      muiTableHeadCellProps: {
        style: {
          padding: '0.75rem 0rem 0.6rem 0.5rem',
        }
      },
      enableEditing: true,
      muiEditTextFieldProps: ({ cell, row, table }) => ({
        disabled: row.original.ro_status[0] === 'pending' ? !row.original.lock_item_4 : row.original.lock_estimate[0],
        onBlur: (event) => {
          var estimate_total = Number(event.target.value);
          const data = {
            vehicle_id: props.active_vehicle.id,
            estimate_total,
          };
          props.sendEstimate(row.original.estimate_id[0], data, 'addEstimateError');
        },
      }),
    },
    {
      accessorKey: 'ro_status',
      enableEditing: false,
      header: 'Status',
      size: 100,
      muiTableHeadCellProps: {
        style: {
          padding: '0.75rem 0rem 0.6rem 0.5rem',
        },
      },
      Cell: ({ renderedCellValue, row }) => (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '1rem',
          }}
        >
          {(() => {
							switch (row.original.ro_status[0]) {
							case 'pending':
								return (
									<div>
                    <input
                      style={{ position: 'relative', top: '2px', marginRight: '6px', cursor: 'pointer' }}
                      type="checkbox"
                      name="bulk-approve"
                      disabled={isDisabled}
                      checked={selectedItems.some((selected) => selected.id === row.id)}
                      onChange={() => handleCheckboxChange(row)}
                    />
										<div className={'btn-group'} role="group" style={{ width: '200px' }}>
											<button className="btn btn-sm btn-success" onClick={onApprove(row.original)} disabled={isDisabled}>Approve</button>
											<button className="btn btn-sm btn-danger" onClick={onDeny(row.original)} disabled={isDisabled}>Deny</button>
										</div>
									</div>
								);

							case 'approved':
								return (
									<button style={{ width: '220px', overflow: 'auto', textWrap: 'auto', textOverflow: 'ellipsis' }} className="btn btn-sm btn-success text-capitalize" title={row.original.approved_by_uid[0] ? props.lookupUserById(row.original.approved_by_uid[0]).user_name : null}>
									Approved
										{' '}
										{row.original.approved_by_uid[0] ? props.lookupUserById(row.original.approved_by_uid[0]).user_name : ''}
                    {' '}
										{row.original.approved_date[0] ? readableStamp(row.original.approved_date[0]) : ''}
									</button>
								);

							case 'declined':
								return <div style={{ height: '85px' }}>
									<div>
										<button style={{ width: '220px', overflow: 'auto', textWrap: 'auto', textOverflow: 'ellipsis' }} className="btn btn-sm btn-danger" title={row.original.declined_by_uid[0] ? props.lookupUserById(row.original.declined_by_uid[0]).user_name : null}>
										Denied
											{' '}
											{row.original.declined_by_uid[0] ? props.lookupUserById(row.original.declined_by_uid[0]).user_name : ''}
                      {' '}
											{row.original.declined_date[0] ? readableStamp(row.original.declined_date[0]) : ''}
										</button>
									</div>
									<div><span className="d-inline">New Amount:</span><AllInputs
								value={row.original.counter_estimate[0] || ''}
								type={'dollar'}
								name={'counter_estimate'}
								parentid={props.active_vehicle.id}
								estimate_id={row.original.estimate_id[0]}
								updatetype="estimate"
								user_name={''}
								updated_at={''}
								vehicle={props.active_vehicle}
									/></div>
								</div>;

							default:
								return row.original.recipients[0].length > 0 ? <button className="btn btn-sm btn-success" onClick={onSend(row.original)}>Get Approved</button> : '(Select Recipient)';
							}
						})()}
        </Box>
      ),
    },
    {
      accessorKey: 'lock_estimate',
      header: 'Delete',
      size: 25,
      muiTableHeadCellProps: {
        style: {
          padding: '0.75rem 0rem 0.6rem 0.5rem',
        }
      },
      enableEditing: false,
      Cell: ({ renderedCellValue, row }) => (
        <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '1rem',
          paddingLeft: '8px',
        }}
        >{row.original.ro_status[0] === 'getapproved' || row.original.ro_status[0] === 'pending' ? (
          <button className="btn btn-sm btn-danger" data-id={row.original.estimate_id[0]} onClick={onDelete}>X</button>
      ) : ''}
        </Box>
      ),
    },
  ];
	const newvehicle = {
    vehicle_id: props.active_vehicle.id,
    ro_status: 'pending',
    lock_estimate: 'false',
  };
	const onDelete = (e) => {
		props.deleteEstimate(e.target.dataset.id, props.active_vehicle.id, 'addDeleteEstimateError');
	};
  const onClick = (e) => {
		props.addEstimate(newvehicle, 'addDeleteEstimateError');
	};

  const selectRecipients = () => {
    const users = props.lookupRecipients();

    const options = users.map((option) => {
          var obj = {
            id: option.id, label: option.user_name, value: option.id,
          };
          return obj;
        });
        return options || [];
      };

  const onApprove = (estimate) => (e) => {
		const subdomain = props.installation.database;
		const message = 'Estimate Approved';
		const data = {
			requested_by_uid: estimate.requested_by_uid[0],
			ro_status: 'approved',
			approved_by_uid: props.current_user.user,
			approved_date: getDateTime(),
			vehicle_id: props.active_vehicle.id,
			lock_estimate: 'true',
		};
    props.sendEstimate(estimate.estimate_id[0], data, 'addEstimateError');
	};

	const onDeny = (estimate) => (e) => {
		const subdomain = props.installation.database;
		const message = 'Estimate Declined';
		const data = {
			vehicle_id: props.active_vehicle.id,
			ro_status: 'declined',
			declined_by_uid: props.current_user.user,
			declined_date: getDateTime(),
		};

    props.sendEstimate(estimate.estimate_id[0], data, 'addEstimateError');
	};

    // Function to handle bulk approve checkbox changes
    const handleCheckboxChange = (item) => {
      setSelectedItems((prev) =>
        prev.some((selected) => selected.id === item.id)
          ? prev.filter((selected) => selected.id !== item.id)
          : [...prev, item]
      );
    };

    const handleBulkActionConfirm = (task) => {
      setConfirmingTask(task);
    };

    // Function to call when the bulk approve button is clicked
    const handleBulkActionButtonClick = (task) => {
      const bulkAction = task === 'Approve' ? onApprove : onDeny;
      if (!bulkAction) {
        console.error(`Invalid task: ${task}`);
        return;
      }
      try {
        for (const item of selectedItems) {
          bulkAction(item.original)();
        }
        // then clear the selectedItems array in case user does one bulk action after another
        setSelectedItems([]);
      } catch (error) {
        console.error('Error processing items:', error);
      } finally {
        setConfirmingTask(null);
      }
    };

  const onSend = (estimate) => (e) => {
		const data = {
			vehicle_id: props.active_vehicle.id,
			requested_by_uid: props.current_user.user,
			requested_date: getDateTime(),
			ro_status: 'pending',
			lock_estimate: 'true',
		};
		props.sendEstimate(estimate.estimate_id[0], data, 'addEstimateError');

		const message = `Vehicle Vin:${props.active_vehicle.vin} Stock: ${props.active_vehicle.stock} requires your approval. Vehicle is currently located in the ${props.lookupDepartmentById(props.active_vehicle.department_id).name} stage`;
		const recipients = estimate.recipients;
	};

  const csvOptions = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: true,
    useBom: true,
    useKeysAsHeaders: false,
    headers: columns.map((c) => c.header),
  };

  const selectWorkItem = () => {
    const options = _.sortBy(props.work_item_presets, 'name').map((option) => {
          var obj = {
            id: option.id,
            label: option.name,
            value: option.name,
            parts_price: option.parts_price,
            labor_units: option.labor_units,
            labor_price: option.labor_price,
            category: option.category,
            estimate_total: option.estimate_total,
            estimate: option.estimate_total,
            estimate_type: option.estimate_type,
            parts_price_editable: option.parts_price_editable,
            labor_price_editable: option.labor_price_editable,
            labor_units_editable: option.labor_units_editable,
            estimate_total_editable: option.estimate_total_editable,
          };
          return obj;
        });
        return options || [];
      };

  const onChangeWorkItem = (estimate_id) => (e) => {
		const errorType = 'Change WorkItem';
        var lock = e.value !== 'Blank Row';
        var data = {
            estimate_total: e.estimate_total,
            labor_price: e.labor_price,
            labor_units: e.labor_units,
            estimate_type: e.estimate_type,
            category: e.category,
            estimate: e.estimate,
            vehicle_id: props.active_vehicle.id,
            description: e.description,
            label_item_1: e.value,
            lock_estimate: lock,
            lock_item_1: e.parts_price_editable,
            lock_item_2: e.labor_units_editable,
            lock_item_3: e.labor_price_editable,
            lock_item_4: e.estimate_total_editable,

        };
		props.sendEstimate(estimate_id, data, 'addEstimateError');
	};

  const onChangeRecipients = (estimate_id) => (e) => {
		const errorType = 'Change WorkItem';

        var data = {
            recipients: e.id,
            vehicle_id: props.active_vehicle.id,
        };
		props.sendEstimate(estimate_id, data, 'addEstimateError');
	};

	const globalTheme = useTheme();

	  const tableTheme = useMemo(
	    () => createTheme({
	        palette: {
	          mode: 'light', // let's use the same dark/light mode as the global theme
	        },
	      }),
	    [globalTheme],
	  );

  	const newdata = [];
    var estimates_total = 0;

    _.orderBy(props.active_vehicle.appraisals, 'id', 'desc').map(estimate => {
		if (estimate.ro_status !== 'expense') {
              if (estimate.ro_status !== 'declined') {estimates_total += Number(estimate.estimate_total);}

              newdata.push({
                estimate: [estimate.estimate],
                ro_number: [estimate.ro_number],
                parts_price: [estimate.parts_price],
                labor_price: [estimate.labor_price],
                labor_units: [estimate.labor_units],
                item_5_type: [estimate.item_5_type],
                description: [estimate.description],
                estimate_total: [estimate.estimate_total],
                ro_status: [estimate.ro_status],
                action: [estimate.ro_status],
                classification: [estimate.classification],
                category: [estimate.category],
                estimate_type: [estimate.estimate_type],
                estimate_id: [estimate.id],
                requested_by_uid: [estimate.requested_by_uid],
                approved_by_uid: [estimate.approved_by_uid],
                approved_date: [estimate.approved_date],
                declined_by_uid: [estimate.declined_by_uid],
                declined_date: [estimate.declined_date],
                declined_reason: [estimate.declined_reason],
                lock_estimate: [estimate.lock_estimate],
                label_item_1: [estimate.label_item_1],
                recipients: [estimate.recipients],
                requested_date: [estimate.requested_date],
                counter_estimate: [estimate.counter_estimate],
                lock_item_1: estimate.lock_item_1,
                lock_item_2: estimate.lock_item_2,
                lock_item_3: estimate.lock_item_3,
                lock_item_4: estimate.lock_item_4,
            });
			}
          });
    // console.log('appraisals', props.active_vehicle.appraisals);
		return (
			<ThemeProvider theme={tableTheme}>
        <div className="container-fluid pad-left-2 pad-right-2 table-create-new-work-item">
          <MaterialReactTable
            tableRef={tableRef}
            columns={columns}
            data={newdata}
            pageSize={20}
            enableEditing
            editDisplayMode={'cell'}
            createDisplayMode={'custom'}
            initialState={{ density: 'compact', pagination: { pageIndex: 0, pageSize: 25 } }}
            renderTopToolbarCustomActions={({ table }) => (
            <Box>
              <Button
                variant="contained"
                onClick={() => {
                  table.setCreatingRow(true);
                  onClick();
                }}
              >
                Create New Work Item
              </Button>
              <Button className="pull-right" style={{ margin: 20 }}>Total: ${estimates_total.toFixed(2)}</Button>

              {confirmingTask ? (
                <div className={'btn-group'} role="group">
                  <button className="btn btn-sm btn-success" onClick={() => handleBulkActionButtonClick(confirmingTask)}>Confirm {confirmingTask} Selected</button>
                  <button className="btn btn-sm btn-danger" onClick={() => setConfirmingTask(null)}>Back</button>
                </div>
              ) : (
                <div className={'btn-group'} role="group">
                  <button className="btn btn-sm btn-success" onClick={() => handleBulkActionConfirm('Approve')} disabled={selectedItems.length === 0 || (isDisabled)}>Approve Selected</button>
                  <button className="btn btn-sm btn-danger" onClick={() => handleBulkActionConfirm('Deny')} disabled={selectedItems.length === 0 || (isDisabled)}>Deny Selected</button>
                </div>
              )}
            </Box>
            )}
            renderDetailPanel={({ row }) => (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-around',
            alignItems: 'center',
          }}
        />
            )}
          />
        </div>
      </ThemeProvider>
		);
};

const mappedActions = {
	addEstimate,
  sendEstimate,
  appraisalRequestNotification,
	appraisalNotification,
  lookupUserById,
  lookupRecipients,
  deleteEstimate,
	lookupDepartmentById,
	lookupDepartmentByName,
	lookupVehicleEstimateById,
	vsearch,
};

function mapStateToProps(state) {
	return {
		active_vehicle: state.settings_refresh.active_vehicle,
		appraisals: state.settings_refresh.appraisals,
		installation: state.settings_dealerships.installation,
		all_tabs: state.settings_site.all_tabs,
    work_item_presets: state.settings_refresh.work_item_presets,
    dealer_settings: state.settings_site.dealer_settings,
    work_item_permissions: state.settings_users.current_user_profile.work_item_approve,
	};
}

export default connect(mapStateToProps, mappedActions)(NewEstimates);
