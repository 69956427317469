import React from 'react';
import {
	BrowserRouter, Route, Switch, Redirect,
} from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ActionCreators } from './actions';
import Dealerships from './containers/Dealerships';
import Appraisals from './containers/Appraisals';
import Carvana from './containers/Carvana';
import LoginPage from './containers/Login/loginPage';
import AuthPage from './containers/Auth/authPage';
import VinSearch from './containers/VinSearch';
import ModalWrapper from './/modals/modalContainer';
import 'bootstrap/scss/bootstrap.scss';
import './style/sidebar-simple.scss';
import './style/vehicle.scss';
import './style/timeline.scss';
import './style/dd.scss';
import './style/switch.scss';
import './style/style-helpers.scss';
import './style/style-custom.scss';
import './assets/fonts/fontfiles.scss';
import './style/style-chat-box.scss';
import './style/highcharts.scss';

function App(props){
		const { access_token } = props.authentication
		return (
			<BrowserRouter>
				<ModalWrapper />
				<Switch>

					{/*Routes that will display whether authenticated or not */}
					<Route
						path="/appraisals/:appraisalToken"
						render={() => <Appraisals {...props} />}
					/>
					<Route
						path="/inspection/:vinToken"
						render={props => <Carvana {...props} />}
					/>
					{/*^^^^Routes that will display whether authenticated or not^^^^*/}



					{/*Routes that will display only when you are NOT authenticated */}
					{!access_token?<Route exact path="/" component={LoginPage} />:null}
					{!access_token?<Route exact path="/require-login" render={() => <LoginPage require_login={true} />} />:null}
					{!access_token?<Route exact path="/auth" component={AuthPage}/>:null}
					{!access_token?<Redirect to="/"/>:null}
					{/*^^^^Routes that will display only when you are NOT authenticated^^^^*/}


					<Route path="/vinsearch/:vinToken" render={()=><Dealerships {...props} />}/>
					<Route exact path="/" render={()=><Dealerships {...props} />}/>
					<Redirect to="/"/>
				</Switch>
			</BrowserRouter>
		)
}

function mapStateToProps(state) {
	return {
		current_user: state.current_user,
		authentication: state.authentication,
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators(ActionCreators, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
