import { PUSH_MODAL, POP_MODAL, CLOSE_MODAL } from "../../actions/types";


const initialState = {
  modalList:[]
}

function modalReducer(state=initialState, action){
  let { modalList } = initialState
  let { modal, numModals } = action
  switch(action.type) {
    case PUSH_MODAL:
      return {
        ...state,
        modalList: [...modalList, modal]
      }
    case POP_MODAL:
      numModals = numModals ? numModals : 1
      return {
        ...state,
        modalList: [...modalList.slice(0, modalList.length-(1 + numModals)) ]
      }
    case CLOSE_MODAL:
      return {
        ...state,
        modalList: []
      }
    default:
      return state
  }
}

export default modalReducer
