import _ from 'lodash';
import React, { useState, useMemo } from 'react';
import classnames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import Modal from 'react-modal';

import { lookupDepartmentById, lookupUserById } from '../../actions/lookups';
import {
  getDateTime,
  getSecondsFromTimeRange,
  getSecondsFromTimeRange_24_5,
  getSecondsFromTimeRange_24_6,
  returnTabAge_24_5,
  returnTabAge_24_6,
  returnTabAge_24_7,
  convertSecondsToDays,
} from '../../utils/dateFunctions';

// Helper function to escape CSV values
const escapeCsvValue = (value) => {
  if (value === null || value === undefined) return '""';
  const stringValue = String(value);
  if (stringValue.includes(',') || stringValue.includes('"') || stringValue.includes('\n') || stringValue.includes('\r')) {
	return `"${stringValue.replace(/"/g, '""')}"`;
  }
  return `"${stringValue}"`;
};

const DownloadCSV = (props) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [onlyLastNote, setOnlyLastNote] = useState(false); // Checkbox for last note
  const [includeAllColumns, setIncludeAllColumns] = useState(false); // Checkbox for all columns
  const dispatch = useDispatch();

  const raw_dealership_columns = useSelector((state) => state.settings_departments.vehicle_columns || []);
  const dealership_columns = raw_dealership_columns.map((column) => column.name);
  const current_department = useSelector((state) => state.settings_departments.department || {});
  const department_properties = current_department.department_properties || [];
  const extra_props = department_properties.map((dept_prop) => dept_prop.vehicle_column.name);
  console.log('Extra properties:', extra_props); // Debug log for extra props
  const work_flows = useSelector((state) => state.settings_departments.work_flows);
  const active_vehicles = useSelector((state) => state.settings_refresh.active_vehicles);
  const dealer_settings = useSelector((state) => state.settings_site.dealer_settings);
  const installation = useSelector((state) => state.settings_dealerships.installation);

  const openModal = () => setModalIsOpen(true);
  const closeModal = () => setModalIsOpen(false);

  const dealerSettingValue = useMemo(() => (name) => {
	const value = _.sortBy(
	  _.filter(dealer_settings, (o) => o.key === name && o.dealership === installation.subdomain),
	  'id'
	);
	if (value.length === 0) {
	  return _.sortBy(
		_.filter(dealer_settings, (o) => o.key === name && (o.dealership === '' || o.dealership === null || o.dealership === undefined)),
		'id'
	  )[0]?.value || '';
	}
	return value[0]?.value || '';
  }, [dealer_settings, installation.subdomain]);

  const downloadCSV = (vehicles, includeAll = false) => {
	const end = new Date();
	const headers2 = [
	  'department',
	  'location',
	  'days in bucket',
	  'total age (days)',
	  'stock',
	  'vin',
	  'year',
	  'make',
	  'model',
	  'color',
	  'miles',
	  'certified',
	  ...extra_props,
	  'notes',
	];
	if (includeAll) headers2.push(...dealership_columns);

	const full_csv = [headers2.map(escapeCsvValue)];
	const work_flow = _.find(work_flows, { name: 'used' }) || {};
	const work_flow_properties = work_flow.work_flow_properties?.length > 0 ? work_flow.work_flow_properties : [];

	vehicles.forEach((vehicle, index) => {
	  const { completed_departments } = vehicle || {};
	  const row = [];
	  const department = vehicle.department_id ? dispatch(lookupDepartmentById(vehicle.department_id)) : {};
	  const deptName = (department.display_name || department.name || '').replace(/[#{}":]/g, ' ').trim();

	  headers2.forEach((column) => {
		let value = '';
		let seconds = 0;
		let plus_seconds = 0;
		switch (column) {
		  case 'department':
			value = deptName;
			break;
		  case 'notes':
			value = Array.isArray(vehicle.vehicle_notes)
			  ? onlyLastNote
				? `${dispatch(lookupUserById(vehicle.vehicle_notes[vehicle.vehicle_notes.length - 1].uid)).user_name} - ${(vehicle.vehicle_notes[vehicle.vehicle_notes.length - 1].value || '').replace(/[#"]/g, '')}`.trim()
				: vehicle.vehicle_notes
					.map((note) => {
					  const user = dispatch(lookupUserById(note.uid));
					  return `${user.user_name} - ${(note.value || '').replace(/[#"]/g, '')}`.trim();
					})
					.join(' | ')
			  : '';
			break;
		  case 'days in bucket':
		  case 'total age (days)':
			const time_tab = column === 'days in bucket' ? 'first_time_tab' : 'second_time_tab';
			const tabSetting = dealerSettingValue(time_tab);
			switch (tabSetting) {
			  case 'age-24_7':
				seconds = getSecondsFromTimeRange(vehicle.created_at, end);
				break;
			  case 'age-24_6':
				seconds = getSecondsFromTimeRange_24_6(vehicle.created_at, end);
				break;
			  case 'age-24_5':
				seconds = getSecondsFromTimeRange_24_5(vehicle.created_at, end);
				break;
			  case 'age-recononly-24_7':
				plus_seconds = getSecondsFromTimeRange(vehicle.department_time, end);
				seconds = returnTabAge_24_7(completed_departments, work_flow_properties);
				break;
			  case 'age-recononly-24_6':
				plus_seconds = getSecondsFromTimeRange_24_6(vehicle.department_time, end);
				seconds = returnTabAge_24_6(completed_departments, work_flow_properties);
				break;
			  case 'age-recononly-24_5':
				plus_seconds = getSecondsFromTimeRange_24_5(vehicle.department_time, end);
				seconds = returnTabAge_24_5(completed_departments, work_flow_properties);
				break;
			  case 'department-24_7':
				seconds = getSecondsFromTimeRange(vehicle.department_time, end);
				break;
			  case 'department-24_6':
				seconds = getSecondsFromTimeRange_24_6(vehicle.department_time, end);
				break;
			  case 'department-24_5':
				seconds = getSecondsFromTimeRange_24_5(vehicle.department_time, end);
				break;
			  case 'department-instance-24_7':
				seconds = getSecondsFromTimeRange(vehicle.department_time, end);
				break;
			  case 'department-instance-24_6':
				seconds = getSecondsFromTimeRange_24_6(vehicle.department_time, end);
				break;
			  case 'department-instance-24_5':
				seconds = getSecondsFromTimeRange_24_5(vehicle.department_time, end);
				break;
			  default:
				seconds = getSecondsFromTimeRange(vehicle.department_time, end);
			}
			value = convertSecondsToDays(seconds + plus_seconds);
			break;
		  default:
			value = vehicle[column] || _.find(vehicle.vehicle_properties, (o) => o.key === column)?.value || '';
		}
		row.push(escapeCsvValue(value));
	  });
	  full_csv.push(row);
	});

	const filename = `${current_department.name || 'export'}_${getDateTime()}.csv`;
	const bom = '\uFEFF';
	const csvString = full_csv.map((row) => row.join(',')).join('\n');
	const csvContent = `${bom}${csvString}`;
	const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
	const link = document.createElement('a');
	const url = URL.createObjectURL(blob);
	link.setAttribute('href', url);
	link.setAttribute('download', filename);
	document.body.appendChild(link);
	link.click();
	document.body.removeChild(link);
	URL.revokeObjectURL(url);
  };

  const onDownloadButtonClick = () => {
	const end = new Date();
	let vehicles = active_vehicles || [];
	vehicles = props.sort_descending 
	  ? _.sortBy(vehicles, props.sort_by_data).reverse()
	  : _.sortBy(vehicles, props.sort_by_data);
	downloadCSV(vehicles, includeAllColumns);
	setModalIsOpen(false);
  };

  const onDownloadStepButtonClick = () => {
	const end = new Date();
	let vehicles = active_vehicles || [];
	vehicles = props.sort_descending 
	  ? _.sortBy(vehicles, props.sort_by_data).reverse()
	  : _.sortBy(vehicles, props.sort_by_data);
	downloadCSV(vehicles, includeAllColumns);
	setModalIsOpen(false);
  };

  return (
	<div>
	  <Modal
		isOpen={modalIsOpen}
		onRequestClose={closeModal}
		contentLabel="DownloadCSV"
		className="d-flex justify-content-center pad-bottom-2 space-bottom-2"
		ariaHideApp={false}
		overlayClassName="overlay"
	  >
		<div className="bg-light col-md-6 md-auto space-top-2 pad-top-1 pad-bottom-1 pad-left-2 pad-right-2">
		  <div className="modal-content">
			<div className="container">
			  <h4 className="modal-title d-inline">Download CSV</h4>
			  <button className="btn btn-sm btn-danger float-end" onClick={closeModal}>Close</button>
			</div>
			<div className="modal-body">
			  <div className="card-block bg-white">
				<div className="row text-center">
				  <label>
					<input
					  type="checkbox"
					  checked={onlyLastNote}
					  onChange={(e) => setOnlyLastNote(e.target.checked)}
					/>
					{' Only include last note'}
				  </label>
				  <br />
				  <label>
					<input
					  type="checkbox"
					  checked={includeAllColumns}
					  onChange={(e) => setIncludeAllColumns(e.target.checked)}
					/>
					{' Include All Columns'}
				  </label>
				</div>
			  </div>
			</div>
			<div className="modal-footer text-center">
			  <button className="btn btn-primary" onClick={onDownloadStepButtonClick}>
				<i className="fa fa-download" /> Download {current_department.name}
			  </button>
			</div>
		  </div>
		</div>
	  </Modal>
	  <span className="btn btn-light btn-md" onClick={openModal}>
		<i className="fa fa-download" />
	  </span>
	</div>
  );
};

export default DownloadCSV;
