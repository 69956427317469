import { PUSH_MODAL, POP_MODAL, CLOSE_MODAL } from "./types";


export function pushModal(modal){
    return(dispatch) => {
        dispatch({
            type: PUSH_MODAL,
            modal
        })
    }
};

export function popModal(numModals = 1){
    return(dispatch) => {
        dispatch({
            type: POP_MODAL,
            numModals
        })
    }
};

export function closeModal(){
    return(dispatch) => {
        dispatch({
            type: CLOSE_MODAL
        })
    }
};


export default {
    modalActions: {
        pushModal,
        popModal,
        closeModal
    }
};