import React, { Component } from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import axios, { post } from 'axios';
import ReactDropzone from 'react-dropzone';
import DropList from './DropList';
import {
	addAttachment,
} from '../../actions/apiAttachments';

class DropBox extends Component {
	constructor(props) {
		super(props);

		this.state = {
			value: this.props.value || '', checked: this.props.checked || '', file: '', image: '', image_name: '', image_type: '', image_size: '', files: [],
		};
		this.onClick = this.onClick.bind(this);
		this.onClickDelete = this.onClickDelete.bind(this);
	}

	onDrop(files) {
		this.setState({
			files,
		});
	}

	onClick() {
		const { files } = this.state;

		files.map((file) => {
			this.fileUpload(file);
		});
		this.setState({
			files: [],
		});
	}

	onClickDelete(name) {
		const newFiles = this.state.files.filter(el => el.name !== name);
		this.setState({
			files: newFiles || [],
		});
	}

	uploadData(response, fileid) {
		const fileurl = response.data;
		const errorType = 'addAttachmentError';
		const { vid } = this.props;
		const { siteid } = this.props;
		const { user_name } = this.props;

		const data = {
			vehicle_id: vid,
			appraisal_id: this.props.estimate_id,
			filename: fileid,
			location: fileurl,
			fileid,
			siteid,
			user_name,
		};

		this.props.addAttachment(data, errorType);
	}

	fileUpload(file) {
		const url = "https://gotoreconfiles.com/cors_new_server.php";
		const self = this;
		const { siteid } = self.props;
		const fileid = file.name;

		const data = new FormData();
		data.append('data', file);
		data.append('action', 'put-newserver-file');
		data.append('fileid', fileid);
		data.append('siteid', siteid);

		return axios.post(url, data, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},

		}).then(response => this.uploadData(response, fileid));
	}

	render() {
		return (
			<div>
				<div className="dropzone">
					<ReactDropzone className="drop-box p-2 text-center space-top-1" onDrop={this.onDrop.bind(this)}>
						{({ getRootProps, getInputProps }) => (
							<section>
								<div {...getRootProps()}>
									<input {...getInputProps()} />
									<p className="text-center btn btn-dark form-control">Drop Files Here or Click to Browse for Files</p>
								</div>
							</section>
						)}
					</ReactDropzone>
				</div>
				<aside>
					<div className="card-header text-white text-capitalize">
						{
							this.state.files.length > 0 ? <button onClick={this.onClick} className="btn btn-success form-control">
								<i className={'fas fa-cloud-upload'} />
								{' '}
Upload Files
								{' '}
								<i className={'fas fa-cloud-upload'} />
                                     </button> : ''
						}
					</div>

					<ul className="list-group">
						{
							this.state.files.map(f => <DropList {...this.props} f={f} key={`dropped_file_${f.name}`} onClickDelete={this.onClickDelete} />)
						}
					</ul>

				</aside>
			</div>
		);
	}
}

const mappedActions = {
	addAttachment,
};

function mapStateToProps(state) {
	return { user_name: state.current_user.user_name };
}

export default connect(mapStateToProps, mappedActions)(DropBox);
