export const RESET_ALL_STATE = 'RESET_ALL_STATE';
export const RESET_NAV = 'RESET_NAV';
export const RESET_SITE = 'RESET_SITE';
export const RESET_ADMIN = 'RESET_ADMIN';
export const RESET_REFRESH = 'RESET_REFRESH';
export const RESET_DEPARTMENTS = 'RESET_DEPARTMENTS';
export const RESET_DEALERSHIP = 'RESET_DEALERSHIP';
export const RESET_REPORTS = 'RESET_REPORTS';
export const RESET_USERS = 'RESET_USERS';
export const RESET_FEED = 'RESET_FEED';
export const RESET_VIN_SCANNER = 'RESET_VIN_SCANNER';
export const SWITCH_DEALERSHIP = 'SWITCH_DEALERSHIP';
export const SET_TIME_RANGE = 'SET_TIME_RANGE';
export const SET_REPORT_TIME_RANGE = 'SET_REPORT_TIME_RANGE';
export const SINGLE_DEALERSHIP = 'SINGLE_DEALERSHIP';
export const AUTH_REQUEST = 'AUTH_REQUEST';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const AUTH_USER = 'AUTH_USER';
export const UNAUTH_USER = 'UNAUTH_USER';
export const AUTH_ERROR = 'AUTH_ERROR';
export const FORGOT_PASSWORD_REQUEST = 'FORGOT_PASSWORD_REQUEST';
export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const USER_LOGOUT = 'USER_LOGOUT';
export const SET_USERS = 'SET_USERS';
export const SET_SEARCHED_USERS = 'SET_SEARCHED_USERS';
export const SET_SINGLE_USER = 'SET_SINGLE_USER';
export const SET_INSTALLATION = 'SET_INSTALLATION';
export const SET_RELATED_INSTALLATIONS = 'SET_RELATED_INSTALLATIONS';
export const SET_ALL_FILTERS = 'SET_ALL_FILTERS';
export const SET_USER_FILTER = 'SET_USER_FILTER';
export const ADD_FILTER = 'ADD_FILTER';
export const DELETE_FILTER = 'DELETE_FILTER';
export const SET_WORK_FLOWS = 'SET_WORK_FLOWS';
export const EDIT_WORK_FLOWS = 'EDIT_WORK_FLOWS';
export const SET_WORK_FLOW = 'SET_WORK_FLOW';
export const SET_EMAIL_REPORTS = 'SET_EMAIL_REPORTS';
export const SET_EMAIL_REPORT = 'SET_EMAIL_REPORT';
export const SET_VEHICLE_HISTORIES = 'SET_VEHICLE_HISTORIES';
export const SET_REPORT_TYPES = 'SET_REPORT_TYPES';
export const SET_REPORT_DEPARTMENTS = 'SET_REPORT_DEPARTMENTS';
export const SET_MONTHLY_REPORT_DEPARTMENTS = 'SET_MONTHLY_REPORT_DEPARTMENTS';
export const SET_REPORT_DEPARTMENT_DATA = 'SET_REPORT_DEPARTMENT_DATA';
export const SET_REPORT_DEALERSHIPS = 'SET_REPORT_DEALERSHIPS';
export const SET_REPORT_MONTHS = 'SET_REPORT_MONTHS';
export const SET_REPORT_START_DATE = 'SET_REPORT_START_DATE';
export const SET_REPORT_END_DATE = 'SET_REPORT_END_DATE';
export const SET_REPORT_DATA = 'SET_REPORT_DATA';
export const SET_REPORT_DATA_FILTERED = 'SET_REPORT_DATA_FILTERED';
export const SET_MONTHLY_REPORT_DATA_FILTERED = 'SET_MONTHLY_REPORT_DATA_FILTERED';
export const SET_SHOULD_LOGIN = 'SET_SHOULD_LOGIN';
export const SET_REPORT_FILTERS = 'SET_REPORT_FILTERS';
export const SET_REPORT_WORK_FLOWS = 'SET_REPORT_WORK_FLOWS';
export const SET_MONTHLY_REPORT_WORK_FLOWS = 'SET_MONTHLY_REPORT_WORK_FLOWS';
export const SET_CUSTOM_MODAL_TAB = 'SET_CUSTOM_MODAL_TAB';
export const VEHICLE_PAGE_NAME = 'VEHICLE_PAGE_NAME';
export const VEHICLE_PAGE_NUMBER = 'VEHICLE_PAGE_NUMBER';
export const VEHICLE_PAGE_SIZE = 'VEHICLE_PAGE_SIZE';
export const VEHICLE_PAGE_VEHICLE_TOTAL = 'VEHICLE_PAGE_VEHICLE_TOTAL';
export const VEHICLE_PAGE_TOTAL = 'VEHICLE_PAGE_TOTAL';
export const REPORTS_READY = 'REPORTS_READY';
export const INCLUDE_SOLD = 'INCLUDE_SOLD';
export const SHOW_ADD_FILTER = 'SHOW_ADD_FILTER';
export const SET_VEHICLE_TAB = 'SET_VEHICLE_TAB';
export const SET_ACTIVE_VEHICLE = 'SET_ACTIVE_VEHICLE';
export const SET_USERS_TAB = 'SET_USERS_TAB';
export const SET_STATS = 'SET_STATS';
export const SET_VEHICLE_FILTER = 'SET_VEHICLE_FILTER';
export const MATCH_DATA = 'MATCH_DATA';
export const SET_DEPARTMENT_COUNTS = 'SET_DEPARTMENT_COUNTS';
export const SET_CREATE_CALENDAR_EVENT = 'SET_CREATE_CALENDAR_EVENT';
export const SET_AUDITS = 'SET_AUDITS';
export const SET_INACTIVE_STATUS_INTERVAL = 'SET_INACTIVE_STATUS_INTERVAL';
export const SET_INACTIVE_STATUS = 'SET_INACTIVE_STATUS';
export const SET_LAST_UPDATED = 'SET_LAST_UPDATED';
export const SET_INTERVAL_NUMBER = 'SET_INTERVAL_NUMBER';
export const SET_INTERVAL_NUMBER_B = 'SET_INTERVAL_NUMBER_B';
export const SET_INTERVAL_VIN_SCANNER = 'SET_INTERVAL_VIN_SCANNER';
export const SET_SINGLE_ESTIMATE = 'SET_SINGLE_ESTIMATE';
export const SET_ESTIMATE_SINGLE = 'SET_ESTIMATE_SINGLE';
export const SET_SINGLE_ESTIMATE_USER = 'SET_SINGLE_ESTIMATE_USER';
export const SET_ESTIMATES = 'SET_ESTIMATES';
export const SET_ACTIVE_APPRAISALS = 'SET_ACTIVE_APPRAISALS';
export const SET_ACTIVE_PART_ORDERS = 'SET_ACTIVE_PART_ORDERS';

export const SET_WORK_ITEM_PRESETS = 'SET_WORK_ITEM_PRESETS';
export const SET_WORK_ITEMS = 'SET_WORK_ITEMS';
export const SET_EDIT_TAB = 'SET_EDIT_TAB';
export const SET_TABS = 'SET_TABS';
export const SET_RECALLS = 'SET_RECALLS';
export const SET_ATTACHMENTS = 'SET_ATTACHMENTS';
export const SET_PHOTOS = 'SET_PHOTOS';
export const SET_CURRENT_REPORT_TAB = 'SET_CURRENT_REPORT_TAB';
export const SET_CURRENT_REPORT = 'SET_CURRENT_REPORT';
export const SET_CURRENT_DEALER_FORM = 'SET_CURRENT_DEALER_FORM';
export const SET_FEED_LOGS = 'SET_FEED_LOGS';
export const SET_DEALER_SETTINGS = 'SET_DEALER_SETTINGS';
export const SET_LOGIN_REDIRECT_DEEP_LINK = 'SET_LOGIN_REDIRECT_DEEP_LINK';
export const SET_DEFAULT_PROFILES = 'SET_DEFAULT_PROFILES';
export const SET_CURRENT_USER_PROFILE = 'SET_CURRENT_USER_PROFILE';
export const SET_CURRENT_TAB = 'SET_CURRENT_TAB';
export const SET_CURRENT_DEFAULT_PROFILE = 'SET_CURRENT_DEFAULT_PROFILE';

export const SET_EDIT_DEPARTMENT_CURRENT_TAB = 'SET_EDIT_DEPARTMENT_CURRENT_TAB';

export const SET_DEALERSHIPS = 'SET_DEALERSHIPS';
export const SET_SEARCHED_DEALERSHIPS = 'SET_SEARCHED_DEALERSHIPS';

export const SET_DEALERSHIP_DISPLAY_OPTIONS = 'SET_DEALERSHIP_DISPLAY_OPTIONS';
export const SET_DEALERSHIP_DISPLAY_FILTERS = 'SET_DEALERSHIP_DISPLAY_FILTERS';
export const SET_DEALERSHIP_USERS = 'SET_DEALERSHIP_USERS';
export const SET_DEALERSHIP_COLUMNS = 'SET_DEALERSHIP_COLUMNS';

export const SET_CURRENT_DEALERSHIP = 'SET_CURRENT_DEALERSHIP';

export const IS_UPDATING = 'IS_UPDATING';
export const IS_LOADING = 'IS_LOADING';
export const LOADING_COMPLETE = 'LOADING_COMPLETE';
export const SET_CANCEL_OLD_REQUESTS = 'SET_CANCEL_OLD_REQUESTS';

export const SET_SEARCHED_VEHICLES = 'SET_SEARCHED_VEHICLES';
export const SET_SINGLE_VEHICLE = 'SET_SINGLE_VEHICLE';
export const SET_FOUND_VEHICLE = 'SET_FOUND_VEHICLE';

export const SET_VEHICLES = 'SET_VEHICLES';
export const SET_REPORT_VEHICLES = 'SET_REPORT_VEHICLES';
export const SET_ALL_VEHICLES = 'SET_ALL_VEHICLES';
export const SET_SOLD_VEHICLES = 'SET_SOLD_VEHICLES';
export const SET_DUPLICATE_VINS = 'SET_DUPLICATE_VINS';

export const ADD_VEHICLE = 'ADD_VEHICLE';
export const VIN_DECODED_VEHICLE = 'VIN_DECODED_VEHICLE';
export const SET_VIN_SCANNER_HEIGHT = 'SET_VIN_SCANNER_HEIGHT';
export const SET_VIN_SCANNER_WIDTH = 'SET_VIN_SCANNER_WIDTH';

export const GET_VEHICLES = 'GET_VEHICLES';

export const SET_VEHICLE_NOTES = 'SET_VEHICLE_NOTES';

export const PROTECTED_TEST = 'PROTECTED_TEST';
export const ADD_FLASH_MESSAGE = 'ADD_FLASH_MESSAGE';
export const DELETE_FLASH_MESSAGE = 'DELETE_FLASH_MESSAGE';
export const DELETE_FLASH_MESSAGES = 'DELETE_FLASH_MESSAGES';

export const SET_PART_ORDERS = 'SET_PART_ORDERS';

export const SET_DEPARTMENT_HISTORIES = 'SET_DEPARTMENT_HISTORIES';

export const SET_ALLOWED_DEPARTMENTS = 'SET_ALLOWED_DEPARTMENTS';
export const SET_DEPARTMENTS = 'SET_DEPARTMENTS';
export const SET_ORIGINAL_DEPARTMENTS = 'SET_ORIGINAL_DEPARTMENTS';
export const SET_DEALERSHIP_DEPARTMENTS = 'SET_DEALERSHIP_DEPARTMENTS';

export const SET_CURRENT_DEPARTMENT = 'SET_CURRENT_DEPARTMENT';
export const SET_EDIT_DEPARTMENT = 'SET_EDIT_DEPARTMENT';
export const SET_EDIT_DEPARTMENTS = 'SET_EDIT_DEPARTMENTS';
export const SET_EDIT_DEPARTMENT_PROPERTIES = 'SET_EDIT_DEPARTMENT_PROPERTIES';
export const SET_EDIT_VEHICLE_COLUMN = 'SET_EDIT_VEHICLE_COLUMN';
export const EDIT_FILTER = 'EDIT_FILTER';

export const DEPARTMENT_COUNT = 'DEPARTMENT_COUNT';
export const SET_INPUT_TYPES = 'SET_INPUT_TYPES';
export const SET_VEHICLE_COLUMNS = 'SET_VEHICLE_COLUMNS';
export const CHANGE_CURRENT_PAGE = 'CHANGE_CURRENT_PAGE';
export const LAST_PAGE = 'LAST_PAGE';

export const REPORT_STATUS = 'REPORT_STATUS';

export const UPDATE_STATUS = 'UPDATE_STATUS';
export const UPDATE_ERROR_STATUS = 'UPDATE_ERROR_STATUS';

export const SET_NOTIFICATION_SETTINGS = 'SET_NOTIFICATION_SETTINGS';

export const TOGGLE_SITE_MAP = 'TOGGLE_SITE_MAP';
export const TOGGLE_REPORT_BAR = 'TOGGLE_REPORT_BAR';
export const TOGGLE_HIDE_BLANK = 'TOGGLE_HIDE_BLANK';
export const TOGGLE_EXPAND_ALL = 'TOGGLE_EXPAND_ALL';
export const TOGGLE_SORT_DESCENDING = 'TOGGLE_SORT_DESCENDING';

export const TOGGLE_NAV = 'TOGGLE_NAV';
export const CLOSE_NAV = 'CLOSE_NAV';
export const OPEN_NAV = 'OPEN_NAV';

export const TOGGLE_SIDEBAR_RIGHT = 'TOGGLE_SIDEBAR_RIGHT';
export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR';
export const CLOSE_SIDEBAR = 'CLOSE_SIDEBAR';
export const OPEN_SIDEBAR = 'OPEN_SIDEBAR';

export const TOGGLE_DRAWER = 'TOGGLE_DRAWER';
export const CLOSE_DRAWER = 'CLOSE_DRAWER';
export const OPEN_DRAWER = 'OPEN_DRAWER';

export const VIN_SCANNER = 'VIN_SCANNER';
export const SET_SORT_BY_DATA = 'SET_SORT_BY_DATA';

export const SET_SUPPORT_TICKETS = 'SET_SUPPORT_TICKETS';
export const SET_ROOMS = 'SET_ROOMS';
export const SET_ROOM_MESSAGES = 'SET_ROOM_MESSAGES';
export const SET_PER_USER = 'SET_PER_USER';

export const TOGGLE_REPORT_NAV = 'TOGGLE_REPORT_NAV';
export const OPEN_REPORT_NAV = 'OPEM_REPORT_NAV';
export const CLOSE_REPORT_NAV = 'CLOSE_REPORT_NAV';
export const SET_DATA_STEP_TARGETS = 'SET_DATA_STEP_TARGETS';
export const SET_EDIT_LOCATIONS = 'SET_EDIT_LOCATIONS';
export const SET_EDIT_USER_LOCATIONS = 'SET_EDIT_USER_LOCATIONS';
export const OPEN_SUPPORT_CHAT = 'OPEN_SUPPORT_CHAT';
export const CLOSE_SUPPORT_CHAT = 'CLOSE_SUPPORT_CHAT';
export const SUPPORT_CHAT_STATUS = 'SUPPORT_CHAT_STATUS';
export const CLOSE_SUPPORT_TICKET = 'CLOSE_SUPPORT_TICKET';
export const OPEN_SUPPORT_TICKET = 'OPEN_SUPPORT_TICKET';
export const SUPPORT_TICKET_STATUS = 'SUPPORT_TICKET_STATUS';
export const RESET_DEALERSHIPS = 'RESET_DEALERSHIPS';



export const UPDATE_USER_LOGIN_INFO = 'UPDATE_USER_LOGIN_INFO'
export const LOGOUT_USER = 'LOGOUT_USER'
export const SET_FEED_PROVIDERS = 'SET_FEED_PROVIDERS';
export const SET_FEED_PROVIDER_RULES = 'SET_FEED_PROVIDER_RULES';
export const SET_FEED_PROVIDER_SETTINGS = 'SET_FEED_PROVIDER_SETTINGS';
export const SET_FEED_NOTIFICATION_SETTINGS = 'SET_FEED_NOTIFICATION_SETTINGS';
export const SET_FEED_ERRORS = 'SET_FEED_ERRORS';


export const PUSH_MODAL = 'PUSH_MODAL';
export const POP_MODAL = 'POP_MODAL';
export const CLOSE_MODAL = 'CLOSE_MODAL';
export const ATTACHMENT_MODAL = 'ATTACHMENT_MODAL';