import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import FileAttachmentSingle from './FileAttachmentSingle';
import DropBox from '../../../../inputs/FileUploadButton/DropBox';
import { deleteAttachment } from '../../../../actions/apiAttachments';
import FilePreview from './FilePreview';

class FileAttachment extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {localAttachments:[]};
		this.onChange = this.onChange.bind(this);
		this.onDelete = this.onDelete.bind(this);
	}

	componentDidMount() {
		this.setState({localAttachments: this.props.active_vehicle.attachments});
	}

	componentDidUpdate(prevProps) {
		if (prevProps.active_vehicle.attachments !== this.props.active_vehicle.attachments) {
			this.setState({localAttachments: this.props.active_vehicle.attachments});
		}
	}

	onDelete = (id) => {
		const { localAttachments } = this.state;
		const newAttachments = localAttachments.filter(attachment => attachment.id !== id);
		this.props.deleteAttachment(id);
		this.setState({ localAttachments: newAttachments });
	};

	onChange(e) {
		this.setState({ [e.target.name]: e.target.value });
	}

	render() {
		const vehicle = this.props.active_vehicle;
		const { localAttachments } = this.state;

		return (
			<div className="container">
				{' '}
				<br />
				<div className="row">
					<div className="col-md-6">
						<div className="card card-default">
							<div className="card-heading" />
							<div className="card-body drop-container">
								<DropBox vid={vehicle.id} siteid={`${this.props.current_dealership}_${vehicle.id}`} />
							</div>

						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-md-12">
						<div className="card card-default">
							<div className="card-heading" />
							<div className="card-body">
								<ul className="list-group">
									{
										localAttachments.length > 0 &&
										localAttachments.map(attachment => <FileAttachmentSingle key={`attach_${attachment.id}`} attachment={attachment} vehicle_id={vehicle.id} data_id={attachment.id} delete={this.onDelete} />)
									}
								</ul>
							</div>

						</div>
					</div>
				</div>
			</div>
		);
	}
}

const mappedActions = {
	deleteAttachment,
};

function mapStateToProps(state) {
	return {
		current_dealership: state.settings_dealerships.current_dealership,
		active_vehicle: state.settings_refresh.active_vehicle,
	};
}
export default connect(mapStateToProps,mappedActions)(FileAttachment);
