import React, { useState, useRef } from "react";
import LoadingSpinner from "../LoadingSpinner/loadingSpinner";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import "./pdfViewer.scss";


const options = {
    cMapUrl: "/cmaps/",
};


export default function PDFviewer(props){
    const { fileName, url } = props;
    const [numPages, setNumPages] = useState(null);
    const [scaleIndex, setScaleIndex] = useState(7);
    const [initialPdfWidth, setInitialPdfWidth] = useState(null);

    // pdf dom element reference
    const pdfElement = useRef(null);
    const setupPdfSizing = (view) => {
        setInitialPdfWidth(pdfElement.current.clientWidth * 0.96);
    };

    // document scaling
    const scaleValues = [.25,.33,.5,.67,.75,.8,.9,1,1.1,1.25,1.5,1.75,2,2.5,3,4,5];
    const decreaseScale = () => {
        if (scaleIndex > 0) {
            setScaleIndex(scaleIndex - 1);
        }
    };
    const increaseScale = () => {
        if (scaleIndex < scaleValues.length - 1) {
            setScaleIndex(scaleIndex + 1);
        }
    };

    // download pdf
    const savePdfAsBlob = async (pdfUrl) => {
        const response = await fetch(pdfUrl);
        const blob = await response.blob();
        return blob;
    };
    const downloadPdf = async () => {
        const fetchedBlob = await savePdfAsBlob(url);
        const downloadBlob = new Blob([fetchedBlob], { type: "application/pdf;base64" });
        const data = window.URL.createObjectURL(downloadBlob);
        const link = document.createElement("a");
        link.href = data;
        link.download = `${fileName.split('.').shift()}.pdf`;
        link.click();
        setTimeout(() => {
            window.URL.revokeObjectURL(data);
        }, 100);
    };

    return (
        <div className="pdf-viewer" ref={pdfElement}>
            <div className="pdf-viewer-header">
                <div className="text-light"><em>{fileName}</em></div>
                <div className="pdf-viewer-header-tools">
                    <button type="button" className="btn btn-sm btn-dark" onClick={decreaseScale}>-</button>
                    <span className="text-light px-2" >{`${Math.round(scaleValues[scaleIndex]*100)} %`}</span>
                    <button type="button" className="btn btn-sm btn-dark" onClick={increaseScale}>+</button>
                </div>
                <button type="button" className="btn btn-sm btn-primary" onClick={downloadPdf}>
                    Download <i className={'fas fa-cloud-download'} />
                </button>
            </div>
            <div className="pdf-viewer-main-content">
                <Document
                    file={url}
                    loading={<LoadingSpinner loading />}
                    onLoadSuccess={(pdf) => {
                        setNumPages(pdf.numPages);
                        pdf.getPage(1).then(page => {
                            setupPdfSizing(page.view);
                        });
                    }}
                    options={options}
                >
                    <Pages numPages={numPages} scale={scaleValues[scaleIndex]} width={initialPdfWidth} />
                </Document>
            </div>
        </div>
    )
}

function Pages(props){
    const{numPages, scale, width} = props;
    return Array.from(new Array(numPages), (el, index) => (
        <Page
            key={`page_${index + 1}`}
            pageNumber={index + 1}
            scale={scale}
            width={width}
            renderTextLayer={false}
            loading={<LoadingSpinner loading />}
        />
    ));
}